import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const CSVUploadModal = ({ show, onHide, onFileUpload }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type !== "text/csv") {
      setError("Por favor, seleccione un archivo CSV válido");
      setFile(null);
    } else {
      setError("");
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError("Por favor, seleccione un archivo");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setIsUploading(true);

    try {
      await onFileUpload(formData);
    } catch (error) {
      setError("Error al procesar el archivo");
    } finally {
      setFile(null);
      setIsUploading(false);
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header
        closeButton
        style={{ backgroundColor: "#004077", color: "white" }}
      >
        <Modal.Title>Importar productos desde CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-black text-white">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "200px" }}
        >
          <h6 className="mb-4">Seleccionar archivo CSV</h6>
          <div className="position-relative w-75">
            <Button
              variant="light"
              className="w-100 py-3 text-center position-relative"
              style={{
                backgroundColor: "#2C2C2C",
                border: "1px dashed #666",
                borderRadius: "8px",
              }}
            >
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                className="position-absolute top-0 start-0 opacity-0 w-100 h-100"
                style={{ cursor: "pointer" }}
              />
              <span className="text-white">
                {file ? file.name : "Ningún archivo seleccionado"}
              </span>
            </Button>
          </div>
          {error && <div className="text-danger mt-3">{error}</div>}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top border-secondary px-4 py-3">
        <div className="d-flex justify-content-between w-100 gap-3">
          <Button
            variant="secondary"
            onClick={onHide}
            className="flex-grow-1 py-2 h-40"
            style={{ maxWidth: "200px", maxHeight: "40px" }}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleUpload}
            disabled={!file || isUploading}
            className="flex-grow-1 py-2 mt-0 h-40"
            style={{ maxWidth: "200px", maxHeight: "40px" }}
          >
            {isUploading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              "Importar"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CSVUploadModal;
