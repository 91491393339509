import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Pagination,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBudgets,
  fetchBudgetById,
  selectBudgets,
  selectBudgetsError,
  selectBudgetsLoading,
  selectBudgetById,
  clearBudget,
} from "../../redux/reducers/budgetsReducer";
import { selectLoggedUserProfile } from "../../redux/reducers/authReducer";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import SearchBar from "../../components/Searchbar";
import NewBudgetModal from "./NewBudgetModal";
import EditBudgetModal from "./EditBudgetModal";
import DeleteBudgetModal from "./DeleteBudgetModal";
import { FaBox, FaFileInvoice, FaPlus } from "react-icons/fa";

const Budgets = () => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const loading = useSelector(selectBudgetsLoading);
  const budgets = useSelector(selectBudgets);
  const error = useSelector(selectBudgetsError);
  const budget = useSelector(selectBudgetById);
  const profilesAuth = useSelector(selectLoggedUserProfile);
  useEffect(() => {
    dispatch(fetchBudgets(null));
  }, [dispatch]);

  const hasManageBudgetsAccess = profilesAuth?.some((profile) =>
    profile.accesses?.some((access) => access.name === "manage_budgets")
  );

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewEntityModal, setShowNewEntityModal] = useState(false);

  // Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [sortedBudgets, setSortedBudgets] = useState([]);
  const itemsPerPage = 7;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedBudgets.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(budgets.length / itemsPerPage);

  // Calcular el rango de páginas a mostrar en la paginación
  const maxVisiblePages = 3; // Número máximo de páginas visibles
  const [visiblePages, setVisiblePages] = useState([]);

  useEffect(() => {
    const calculateVisiblePages = () => {
      const midPage = Math.ceil(maxVisiblePages / 2);
      let startPage = currentPage - midPage + 1;
      let endPage = currentPage + midPage - 1;

      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(maxVisiblePages, totalPages);
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      setVisiblePages(
        Array.from({ length: endPage - startPage + 1 }, (_, i) => i + startPage)
      );
    };

    calculateVisiblePages();
  }, [currentPage, totalPages, maxVisiblePages]);

  useEffect(() => {
    if (budgets.length > 0) {
      const sorted = [...budgets].sort((a, b) => {
        if (a.category_name < b.category_name) return -1;
        if (a.category_name > b.category_name) return 1;
        if (a.code < b.code) return -1;
        if (a.code > b.code) return 1;
        return 0;
      });
      setSortedBudgets(sorted);
    }
  }, [budgets]);

  const handleEditOpen = (budget) => {
    dispatch(fetchBudgetById(budget));
    setShowEditModal(true);
  };

  const handleEditClose = () => {
    dispatch(clearBudget());
    dispatch(fetchBudgets(null))
    setShowEditModal(false);
  };

  const handleDeleteOpen = (budget) => {
    dispatch(fetchBudgetById(budget));
    setShowDeleteModal(true);
  };

  const handleDeleteClose = (budget) => {
    dispatch(clearBudget());
    dispatch(fetchBudgets(null))
    setShowDeleteModal(false);
  };

  const handleNewEntityOpen = () => {
    setShowNewEntityModal(true);
  };

  const handleNewEntityClose = () => {
    setShowNewEntityModal(false);
  };

  //Searchbar
  const handleSearch = (text) => {
    dispatch(fetchBudgets(text));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });

    const sorted = [...sortedBudgets].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setSortedBudgets(sorted);
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? " ▲" : " ▼";
    }
    return "";
  };

  return (
    <Container className="pt-4 pb-4">
      <Row>
        <Col>
          <Container className="pb-4 align-items-center">
            <Row className="align-items-center">
              <Col sm="4" md="4" lg="4">
                <h1 style={{ marginBottom: 0 }}>Presupuestos</h1>
              </Col>
              <Col sm="6" md="6" lg="6">
                <SearchBar
                  onSearch={handleSearch}
                  parameterName={"descripción o código"}
                />
              </Col>
              {hasManageBudgetsAccess && (
                <Col sm="2" md="2" lg="2">
                  <Button
                    variant="primary"
                    className="custom-button d-flex align-items-center justify-content-center"
                    onClick={handleNewEntityOpen}
                  >
                    <FaPlus/>
                    <FaFileInvoice size={30} style={{ marginLeft: "10px" }} />
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : budgets.length === 0 ? (
            <p>No se encontraron presupuestos.</p>
          ) : (
            <>
              <Table
                striped
                bordered
                hover
                variant="dark"
                className="custom-table"
              >
                <thead>
                  <tr>
                    <th
                      style={{ textAlign: "center" }}
                      onClick={() => handleSort("delivery_date")}
                      className="custom-table-header"
                    >
                      Fecha {getSortIndicator("delivery_date")}
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      onClick={() => handleSort("user_name")}
                      className="custom-table-header"
                    >
                      Usuario {getSortIndicator("user_name")}
                    </th>
                    <th
                      onClick={() => handleSort("client_name")}
                      className="custom-table-header"
                    >
                      Cliente {getSortIndicator("client_name")}
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      onClick={() => handleSort("crop_type_name")}
                      className="custom-table-header"
                    >
                      Tipo de cultivo {getSortIndicator("crop_type_name")}
                    </th>
                    <th
                      style={{ textAlign: "right" }}
                      onClick={() => handleSort("total_amount")}
                      className="custom-table-header"
                    >
                      Total (US$) {getSortIndicator("total_amount")}
                    </th>
                    {hasManageBudgetsAccess && (
                      <th
                        style={{ textAlign: "center", width: "120px" }}
                        className="custom-table-header"
                      >
                        Acciones
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((budget) => (
                    <tr key={budget.uuid}>
                      <td style={{ textAlign: "center" }}>
                        {budget.delivery_date}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {budget.user_name}
                      </td>
                      <td>{budget.client_name}</td>
                      <td style={{ textAlign: "center" }}>
                        {budget.crop_type_name}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {new Intl.NumberFormat("es-AR", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(budget.total_amount)}
                      </td>
                      {hasManageBudgetsAccess && (
                        <td style={{ textAlign: "center" }}>
                          <Button
                            variant="warning"
                            size="sm"
                            className="me-2"
                            onClick={() => handleEditOpen(budget.uuid)}
                          >
                            <PencilSquare />
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteOpen(budget.uuid)}
                          >
                            <Trash />
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Pagination className="custom-pagination">
                {visiblePages.map((pageNumber) => (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </Pagination.Item>
                ))}
              </Pagination>
            </>
          )}
          {/* Modales */}
          <NewBudgetModal
            showNewEntityModal={showNewEntityModal}
            handleClose={handleNewEntityClose}
            handleSearch={handleSearch}
          />
          <EditBudgetModal
            showEditModal={showEditModal}
            handleClose={handleEditClose}
            handleSearch={handleSearch}
            budget={budget}
          />
          <DeleteBudgetModal
            showDeleteModal={showDeleteModal}
            handleClose={handleDeleteClose}
            handleSearch={handleSearch}
            budget={budget}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Budgets;
