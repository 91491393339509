import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import { Outlet, useNavigate, Link, useLocation } from "react-router-dom";
import "./styles/Layout.css";
import img_logo from "../assets/metzer-logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUserLogged,
  selectLoggedUserProfile,
  selectLoggedUserUserName,
  selectToken,
} from "../redux/reducers/authReducer";
import { PersonCircle } from "react-bootstrap-icons";
import { changePasswordWithAuth } from "../redux/actions/authThunks";
import ChangePasswordModal from "./ChangePasswordModal";

const Layout = () => {
  const username = useSelector(selectLoggedUserUserName);
  const profiles = useSelector(selectLoggedUserProfile);
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("danger");

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  });

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);

  const moduleToPath = {
    Perfiles: "/home/profiles",
    Usuarios: "/home/users",
    Clientes: "/home/clients",
    Proveedor: "/home/providers",
    Productos: "/home/products",
    Categorías: "/home/categories",
    Precios: "/home/prices",
    Cultivos: "/home/crop_types",
    Etapas: "/home/stage_types",
    Presupuestos: "/home/budgets",
  };

  const allowedLinks = profiles
    ? profiles.flatMap((profile) =>
        profile.accesses.map((access) => access.name)
      )
    : [];

  useEffect(() => {
    const currentPathAccess = Object.values(moduleToPath).find((path) =>
      location.pathname.startsWith(path)
    );
    if (
      currentPathAccess &&
      !allowedLinks.includes(`view_${currentPathAccess.slice(6).toLowerCase()}`)
    ) {
      navigate("/home");
    }
  }, [allowedLinks, location.pathname, navigate, moduleToPath]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    const { oldPassword, newPassword, repeatNewPassword } = formData;

    // Validate if new passwords match
    if (newPassword !== repeatNewPassword) {
      setAlertMessage("Las contraseñas nuevas no coinciden.");
      setShowAlert(true);
      return;
    }

    try {
      // Dispatch the wrapped action
      const response = await dispatch(
        changePasswordWithAuth({
          current_password: oldPassword,
          new_password: newPassword,
        })
      );

      // Handle response
      if (response.status === 200) {
        setAlertMessage("Contraseña actualizada exitosamente.");
        setAlertColor("success");
        setShowAlert(true);
      } else {
        setAlertMessage(
          response.data.message || "Error al cambiar la contraseña."
        );
        setAlertColor("danger");
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
      setAlertMessage("Conexión no disponible.");
      setShowAlert(true);
    }
  };

  return (
    <Container fluid className="d-flex flex-column vh-100">
      <Row className="flex-grow-1">
        <Col xs={2} id="sidebar-wrapper" className="sidebar d-flex flex-column">
          <div className="pt-4 pb-2 text-center">
            <img
              src={img_logo}
              alt="Logo Metzer"
              style={{ width: "60%", height: "100%", objectFit: "cover" }}
            />
          </div>
          <hr
            style={{ color: "#f8f9fa", backgroundColor: "#f8f9fa", height: 3 }}
          />
          <div className="nav-scrollable">
            <Nav className="col-md-12 d-none d-md-block flex-column">
              <div className="sidebar-sticky"></div>
              <Nav.Item>
                <Card
                  className={
                    location.pathname === "/home"
                      ? "custom-card-active"
                      : "custom-card"
                  }
                >
                  <Nav.Link
                    className={
                      location.pathname === "/home"
                        ? "custom-link-active"
                        : "custom-link"
                    }
                    as={Link}
                    to="/home"
                  >
                    Home
                  </Nav.Link>
                </Card>
              </Nav.Item>
              {Object.keys(moduleToPath).map((moduleName, index) => {
                return (
                  allowedLinks.includes(
                    `view_${moduleToPath[moduleName].slice(6).toLowerCase()}`
                  ) && (
                    <Nav.Item key={index}>
                      <Card
                        className={
                          location.pathname === moduleToPath[moduleName]
                            ? "custom-card-active"
                            : "custom-card"
                        }
                      >
                        <Nav.Link
                          className={
                            location.pathname === moduleToPath[moduleName]
                              ? "custom-link-active"
                              : "custom-link"
                          }
                          as={Link}
                          to={moduleToPath[moduleName]}
                        >
                          {moduleName}
                        </Nav.Link>
                      </Card>
                    </Nav.Item>
                  )
                );
              })}
            </Nav>
          </div>
          <Container className="mt-3 pb-4">
            <Row className="align-items-center justify-content-center text-center">
              <Col sm="2" md="2" lg="2">
                <PersonCircle size={35} fill="#0d1b25" />
              </Col>
              <Col sm="7" md="7" lg="7">
                <Card.Text>
                  <strong style={{ color: "#0d1b25", fontSize: "20px" }}>
                    {username}
                  </strong>
                </Card.Text>
              </Col>
            </Row>
            <Button size="sm" onClick={handleShowModal}>
              Cambiar contraseña
            </Button>
            <Button
              size="sm"
              style={{ marginTop: 4, marginBottom: 0 }}
              onClick={() => dispatch(clearUserLogged())}
            >
              Cerrar sesión
            </Button>
          </Container>
        </Col>
        <Col xs={10} id="page-content-wrapper" className="pt-2">
          <Outlet />
        </Col>
      </Row>

      {/* Modal */}
      <ChangePasswordModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        formData={formData}
        handleFormChange={handleFormChange}
        handlePasswordChange={handlePasswordChange}
        showAlert={showAlert}
        alertColor={alertColor}
        alertMessage={alertMessage}
      />
    </Container>
  );
};

export default Layout;
