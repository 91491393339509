import React from 'react';
import { Modal, Table, Button } from 'react-bootstrap';

const ValidatePricesModal = ({
  showModal,
  validationResult,
  selectedProvider,
  handleCloseModal,
  handleUpdatePrices
}) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Resultados de la Validación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {validationResult && validationResult.valid_prices && validationResult.valid_prices.length > 0 ? (
          <Table striped bordered hover variant="dark" className='custom-table'>
            <thead>
              <tr>
                <th className="custom-table-header">Producto</th>
                <th style={{ textAlign: 'center' }} className="custom-table-header">Unidad</th>
                <th className="custom-table-header">Proveedor</th>
                <th className="custom-table-header">Precio</th>
              </tr>
            </thead>
            <tbody>
              {validationResult.valid_prices.map((price, index) => (
                <tr key={index}>
                  <td>{price.product_description}</td>
                  <td style={{ textAlign: 'center' }}>{price.unit_type}</td>
                  <td>{selectedProvider.name}</td>
                  <td>
                    {price.price.toLocaleString('es-AR', {
                      style: 'currency',
                      currency: 'ARS',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 20,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No hay precios válidos.</p>
        )}
        {validationResult && validationResult.errors && validationResult.errors.length > 0 && (
          <div>
            <h5>Errores</h5>
            <ul>
              {validationResult.errors.map((error, index) => (
                <li key={index}>
                  <strong>Código:</strong> {error.product_code} -&nbsp;
                  <strong>Precio:</strong> {error.price} -&nbsp;
                  <strong>Error:</strong> {error.error}
                </li>
              ))}
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => handleUpdatePrices(validationResult.valid_prices)}
        >
          Actualizar Precios
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ValidatePricesModal;
