import { createSlice } from "@reduxjs/toolkit";
import { withAuthentication } from "../middlewares";
import {
  createProduct,
  deleteProductById,
  editProductById,
  getProductById,
  getProductsWithFilters,
  searchProductsByCode
} from "../actions/productsThunks";

const initialState = {
  data: [],
  product: null,
  loading: false,
  error: null,
  successMessage: null,
  errorMessage: null,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    //Traer todos los productos
    fetchProductsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchProductsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchProductsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    //Traer un producto para edición/detalle
    fetchProductStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchProductSuccess(state, action) {
      state.loading = false;
      state.product = action.payload;
    },
    fetchProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearProduct(state) {
      state.product = null;
    },
    //Editar un producto
    editProductStart(state) {
      state.loading = true;
      state.error = null;
    },
    editProductSuccess(state, action) {
      state.loading = false;
      state.product = action.payload;
      state.successMessage = "Operación exitosa";
      state.errorMessage = null;
    },
    editProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.errorMessage = "Error en la operación";
      state.successMessage = null;
    },
    editCloseMessage(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
    //Borrar un producto
    deleteProductStart(state) {
      state.loading = true;
      state.error = null;
    },
    deleteProductSuccess(state, action) {
      state.loading = false;
      state.error = null;

      const productuuid = action.payload;
      const deletedProductIndex = state.data.findIndex(
        (product) => product.uuid === productuuid
      );

      if (deletedProductIndex !== -1) {
        state.data.splice(deletedProductIndex, 1);
        if (state.product && state.product.uuid === productuuid) {
          state.product = null;
        }
      }
    },
    deleteProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    //Crear producto
    createNewProductStart(state) {
      state.loading = true;
      state.error = null;
    },
    createNewProductSuccess(state, action) {
      state.loading = false;
      state.product = action.payload;
      state.successMessage = "Producto creado exitosamente";
      state.errorMessage = null;
    },
    createNewProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.errorMessage = "Error al crear producto";
      state.successMessage = null;
    },

    searchProductsStart(state) {
      state.loading = true;
      state.error = null;
    },
    searchProductsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.product_providers;
    },
    searchProductsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const fetchProducts = withAuthentication(
  (authToken, ...args) =>
    async (dispatch, getState) => {
      dispatch(fetchProductsStart());
      try {
        const response = await getProductsWithFilters(authToken, ...args);
        const products = response.products || [];
        dispatch(fetchProductsSuccess(products));
      } catch (error) {
        dispatch(fetchProductsFailure(error.message));
      }
    }
);

export const fetchProductById = withAuthentication(
  (authToken, productuuid) => async (dispatch, getState) => {
    dispatch(fetchProductStart());
    try {
      const product = await getProductById(authToken, productuuid);
      dispatch(fetchProductSuccess(product));
    } catch (error) {
      dispatch(fetchProductFailure(error.message));
    }
  }
);

export const createNewProduct = withAuthentication(
  (authToken, newProduct) => async (dispatch, getState) => {
    dispatch(createNewProductStart());
    try {
      const response = await createProduct(authToken, newProduct);
      const product = response.product;
      dispatch(createNewProductSuccess(product));
    } catch (error) {
      dispatch(createNewProductFailure(error.message));
    }
  }
);

export const editProduct = withAuthentication(
  (authToken, productuuid, editedProduct) => async (dispatch, getState) => {
    dispatch(editProductStart());
    try {
      const response = await editProductById(
        authToken,
        productuuid,
        editedProduct
      );
      const product = response.product || {};
      dispatch(editProductSuccess(product));
    } catch (error) {
      dispatch(editProductFailure(error.message));
    }
  }
);

export const deleteProduct = withAuthentication(
  (authToken, productuuid) => async (dispatch, getState) => {
    dispatch(deleteProductStart());
    try {
      const response = await deleteProductById(authToken, productuuid);
      dispatch(deleteProductSuccess(response.productuuid));
    } catch (error) {
      dispatch(deleteProductFailure(error.message));
    }
  }
);

export const searchProductsByCodeThunk = withAuthentication(
  (authToken, code) => async (dispatch) => {
    dispatch(searchProductsStart());
    try {
      const response = await searchProductsByCode(authToken, code);
      dispatch(searchProductsSuccess(response));
      return response;
    } catch (error) {
      dispatch(searchProductsFailure(error.message));
    }
  }
);

export const {
  fetchProductsStart,
  fetchProductsSuccess,
  fetchProductsFailure,
  fetchProductStart,
  fetchProductSuccess,
  fetchProductFailure,
  clearProduct,
  editProductStart,
  editProductSuccess,
  editProductFailure,
  editCloseMessage,
  deleteProductStart,
  deleteProductSuccess,
  deleteProductFailure,
  createNewProductStart,
  createNewProductSuccess,
  createNewProductFailure,

  searchProductsStart,
  searchProductsSuccess,
  searchProductsFailure,
} = productsSlice.actions;

export const selectProducts = (state) => state.products.data;
export const selectProductById = (state) => state.products.product;
export const selectProductsLoading = (state) => state.products.loading;
export const selectProductsError = (state) => state.products.error;
export const selectProductsSuccessMessage = (state) =>
  state.products.successMessage;
export const selectProductsErrorMessage = (state) =>
  state.products.errorMessage;

export default productsSlice.reducer;
