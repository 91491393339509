import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  editStageType,
  editCloseMessage,
  selectStageTypesErrorMessage,
  selectStageTypesSuccessMessage,
} from "../../redux/reducers/stageTypesReducer";

const EditStageTypeModal = ({
  showEditModal,
  handleClose,
  handleSearch,
  stageType,
}) => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();

  // Mensaje de éxito o error
  const successMessage = useSelector(selectStageTypesSuccessMessage);
  const errorMessage = useSelector(selectStageTypesErrorMessage);

  const handleCloseMessage = () => {
    handleClose();
    setFormData({
      name: "",
      code: "",
      order: "",
    });
    dispatch(editCloseMessage());
    handleSearch();
  };

  // FormData inicial
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    order: "",
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;

    // Validación para el porcentaje de error
    if (name === "error_rate") {
      const regex = /^\d{0,3}(\.\d{0,3})?$/; // Hasta 3 enteros y 3 decimales
      if (!regex.test(value) && value !== "") {
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (stageType) {
      setFormData({
        name: stageType.name,
        code: stageType.code,
        order: stageType.order,
      });
      setInitialFormData({
        name: stageType.name,
        code: stageType.code,
        order: stageType.order,
      });
    }
  }, [stageType]);

  // Cambios en el formulario
  const [initialFormData, setInitialFormData] = useState();
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (initialFormData) {
      const hasChanges =
        JSON.stringify(initialFormData) !== JSON.stringify(formData);
      setIsChanged(hasChanges);
    }
  }, [formData, initialFormData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(editStageType(stageType.uuid, formData));
  };

  return (
    <div>
      <Modal show={showEditModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Editar tipo de cultivo</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: "8px" }}>
          <form style={{ display: "contents" }} onSubmit={handleSubmit}>
            <div>
              <Row className="pb-2">
                <Col md={6}>
                  <InputGroup>
                    <InputGroup.Text>Nombre:</InputGroup.Text>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Nombre..."
                      value={formData.name}
                      onChange={handleFormChange}
                      style={{ marginTop: 0, height: "100%" }}
                      required
                    />
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <InputGroup>
                    <InputGroup.Text>Código:</InputGroup.Text>
                    <Form.Control
                      name="code"
                      type="text"
                      placeholder="Código..."
                      value={formData.code}
                      onChange={handleFormChange}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^a-zA-Z]/g, "")
                          .slice(0, 1);
                      }}
                      style={{ marginTop: 0, height: "100%" }}
                      required
                    />
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <InputGroup>
                    <InputGroup.Text>Órden:</InputGroup.Text>
                    <Form.Control
                      name="order"
                      type="number"
                      placeholder="Órden..."
                      value={formData.order}
                      onChange={handleFormChange}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9]/g, "")
                          .slice(0, 2);
                      }}
                      style={{ marginTop: 0, height: "100%" }}
                      required
                    />
                  </InputGroup>
                </Col>
              </Row>
            </div>
            <Modal.Footer>
              {!isChanged && (
                <p className="form-text text-muted">
                  Debe realizar cambios para guardar
                </p>
              )}
              <Button
                variant="primary"
                style={{ marginTop: "0px" }}
                onClick={handleSubmit}
                disabled={!isChanged}
              >
                Guardar cambios
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
        <Modal
          show={!!successMessage || !!errorMessage}
          onHide={handleCloseMessage}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {errorMessage
                ? "Error en la modificación"
                : "Modificación exitosa"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{successMessage || errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMessage}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal>
    </div>
  );
};

export default EditStageTypeModal;
