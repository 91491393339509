import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCropTypes,
  selectCropTypes,
  selectCropTypesError,
  selectCropTypesLoading,
  selectCropTypeById,
  clearCropType,
  fetchCropType,
} from "../../redux/reducers/cropTypesReducer";
import { selectLoggedUserProfile } from "../../redux/reducers/authReducer";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import SearchBar from "../../components/Searchbar";
import "../styles/Categories.css";
import NewCropTypeModal from "./NewCropTypeModal";
import EditCropTypeModal from "./EditCropTypeModal";
import DeleteCropTypeModal from "./DeleteCropTypeModal";
import { FaPlus, FaSeedling } from "react-icons/fa";

const CropTypes = () => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const loading = useSelector(selectCropTypesLoading);
  const cropTypes = useSelector(selectCropTypes);
  const error = useSelector(selectCropTypesError);
  const cropType = useSelector(selectCropTypeById);
  const profilesAuth = useSelector(selectLoggedUserProfile);

  useEffect(() => {
    dispatch(fetchCropTypes(null));
  }, [dispatch]);

  const hasManageCropTypesAccess = profilesAuth?.some((profile) =>
    profile.accesses?.some((access) => access.name === "manage_crop_types")
  );

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewEntityModal, setShowNewEntityModal] = useState(false);

  const handleEditOpen = (crop_type_uuid) => {
    dispatch(fetchCropType(crop_type_uuid));
    setShowEditModal(true);
  };

  const handleEditClose = () => {
    dispatch(clearCropType());
    setShowEditModal(false);
  };

  const handleDeleteOpen = (crop_type_uuid) => {
    dispatch(fetchCropType(crop_type_uuid));
    setShowDeleteModal(true);
  };

  const handleDeleteClose = (crop_type_uuid) => {
    dispatch(clearCropType());
    setShowDeleteModal(false);
  };

  const handleNewEntityOpen = () => {
    setShowNewEntityModal(true);
  };

  const handleNewEntityClose = () => {
    setShowNewEntityModal(false);
  };

  //Searchbar
  const handleSearch = (text) => {
    dispatch(fetchCropTypes(text));
  };

  return (
    <Container className="pt-4 pb-4">
      <Row>
        <Col>
          <Container className="pb-4 align-items-center">
            <Row className="align-items-center">
              <Col sm="3" md="3" lg="3">
                <h1 style={{ marginBottom: 0 }}>Cultivos</h1>
              </Col>
              <Col sm="7" md="7" lg="7">
                <SearchBar onSearch={handleSearch} parameterName={"nombre"} />
              </Col>
              {hasManageCropTypesAccess && (
                <Col sm="2" md="2" lg="2">
                  <Button
                    variant="primary"
                    className="custom-button d-flex align-items-center justify-content-center"
                    onClick={handleNewEntityOpen}
                  >
                    <FaPlus />
                    <FaSeedling size={30} style={{ marginLeft: "10px" }} />
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : cropTypes.length === 0 ? (
            <p>No se encontraron tipos de cultivo.</p>
          ) : (
            <>
              <Table
                striped
                bordered
                hover
                variant="dark"
                className="custom-table"
              >
                <thead>
                  <tr>
                    <th className="custom-table-header">Nombre</th>
                    {hasManageCropTypesAccess && (
                      <th
                        style={{ textAlign: "center", width: "120px" }}
                        className="custom-table-header"
                      >
                        Acciones
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {cropTypes.map((cropType) => (
                    <tr key={cropType.uuid}>
                      <td>{cropType.name}</td>
                      {hasManageCropTypesAccess && (
                        <td style={{ textAlign: "center" }}>
                          <Button
                            variant="warning"
                            size="sm"
                            className="me-2"
                            onClick={() => handleEditOpen(cropType.uuid)}
                          >
                            <PencilSquare />
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteOpen(cropType.uuid)}
                          >
                            <Trash />
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}

          {/*POPUPS*/}
          <NewCropTypeModal
            showNewEntityModal={showNewEntityModal}
            handleClose={handleNewEntityClose}
            handleSearch={handleSearch}
          />
          <EditCropTypeModal
            showEditModal={showEditModal}
            handleClose={handleEditClose}
            cropType={cropType}
            handleSearch={handleSearch}
          />
          <DeleteCropTypeModal
            showDeleteModal={showDeleteModal}
            handleClose={handleDeleteClose}
            cropType={cropType}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CropTypes;
