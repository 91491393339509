import { createSlice } from '@reduxjs/toolkit';
import { withAuthentication } from '../middlewares';
import {
    getCropTypesWithFilters,
    getCropTypeById,
    createCropType,
    editCropTypeById,
    deleteCropTypeById,
} from '../actions/cropTypesThunks';

const initialState = {
    data: [], // Lista de tipos de cultivos
    cropType: null, // Detalle de un tipo de cultivo
    loading: false, // Indicador de carga
    error: null, // Error
    successMessage: null, // Mensaje de éxito
    errorMessage: null, // Mensaje de error
};

const cropTypesSlice = createSlice({
    name: 'cropTypes',
    initialState,
    reducers: {
        // Obtener todos los tipos de cultivos
        fetchCropTypesStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchCropTypesSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchCropTypesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        // Obtener un tipo de cultivo por UUID
        fetchCropTypeStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchCropTypeSuccess(state, action) {
            state.loading = false;
            state.cropType = action.payload;
        },
        fetchCropTypeFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearCropType(state) {
            state.cropType = null;
        },
        // Crear un nuevo tipo de cultivo
        createCropTypeStart(state) {
            state.loading = true;
            state.error = null;
        },
        createCropTypeSuccess(state, action) {
            state.loading = false;
            state.data.push(action.payload);
            state.successMessage = 'Tipo de cultivo creado exitosamente';
        },
        createCropTypeFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        // Editar un tipo de cultivo por UUID
        editCropTypeStart(state) {
            state.loading = true;
            state.error = null;
        },
        editCropTypeSuccess(state, action) {
            state.loading = false;
            const updatedCropType = action.payload;
            const index = state.data.findIndex((crop) => crop.uuid === updatedCropType.uuid);
            if (index !== -1) {
                state.data[index] = updatedCropType;
            }
            state.successMessage = 'Tipo de cultivo actualizado exitosamente';
        },
        editCropTypeFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        editCloseMessage(state) {
          state.successMessage = null;
          state.errorMessage = null;
        },
        // Eliminar un tipo de cultivo por UUID
        deleteCropTypeStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteCropTypeSuccess(state, action) {
            state.loading = false;
            const uuid = action.payload;
            state.data = state.data.filter((crop) => crop.uuid !== uuid);
            state.successMessage = 'Tipo de cultivo eliminado exitosamente';
        },
        deleteCropTypeFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearMessages(state) {
            state.successMessage = null;
            state.errorMessage = null;
        },
    },
});

// Thunks asociados con `withAuthentication`
export const fetchCropTypes = withAuthentication((authToken, filters) => async (dispatch) => {
    dispatch(fetchCropTypesStart());
    try {
        const response = await getCropTypesWithFilters(authToken, filters);
        dispatch(fetchCropTypesSuccess(response.crop_types || []));
    } catch (error) {
        dispatch(fetchCropTypesFailure(error.message));
    }
});

export const fetchCropType = withAuthentication((authToken, cropTypeUuid) => async (dispatch) => {
    dispatch(fetchCropTypeStart());
    try {
        const response = await getCropTypeById(authToken, cropTypeUuid);
        dispatch(fetchCropTypeSuccess(response));
    } catch (error) {
        dispatch(fetchCropTypeFailure(error.message));
    }
});

export const createNewCropType = withAuthentication((authToken, newCropType) => async (dispatch) => {
    dispatch(createCropTypeStart());
    try {
        const response = await createCropType(authToken, newCropType);
        dispatch(createCropTypeSuccess(response));
    } catch (error) {
        dispatch(createCropTypeFailure(error.message));
    }
});

export const editCropType = withAuthentication((authToken, cropTypeUuid, editedCropType) => async (dispatch) => {
    dispatch(editCropTypeStart());
    try {
        const response = await editCropTypeById(authToken, cropTypeUuid, editedCropType);
        dispatch(editCropTypeSuccess(response));
    } catch (error) {
        dispatch(editCropTypeFailure(error.message));
    }
});

export const deleteCropType = withAuthentication((authToken, cropTypeUuid) => async (dispatch) => {
    dispatch(deleteCropTypeStart());
    try {
        await deleteCropTypeById(authToken, cropTypeUuid);
        dispatch(deleteCropTypeSuccess(cropTypeUuid));
    } catch (error) {
        dispatch(deleteCropTypeFailure(error.message));
    }
});

// Acciones exportadas
export const {
    fetchCropTypesStart,
    fetchCropTypesSuccess,
    fetchCropTypesFailure,
    fetchCropTypeStart,
    fetchCropTypeSuccess,
    fetchCropTypeFailure,
    clearCropType,
    createCropTypeStart,
    createCropTypeSuccess,
    createCropTypeFailure,
    editCropTypeStart,
    editCropTypeSuccess,
    editCropTypeFailure,
    editCloseMessage,
    deleteCropTypeStart,
    deleteCropTypeSuccess,
    deleteCropTypeFailure,
    clearMessages,
} = cropTypesSlice.actions;

// Selectores
export const selectCropTypes = (state) => state.cropTypes.data;
export const selectCropTypeById = (state) => state.cropTypes.cropType;
export const selectCropTypesLoading = (state) => state.cropTypes.loading;
export const selectCropTypesError = (state) => state.cropTypes.error;
export const selectCropTypesSuccessMessage = (state) => state.cropTypes.successMessage;
export const selectCropTypesErrorMessage = (state) =>
  state.cropTypes.errorMessage;
// Reducer exportado
export default cropTypesSlice.reducer;