import React, { useMemo } from "react";
import { Card, Row, Col, Table } from "react-bootstrap";

const BudgetPreview = ({
  budgetData,
  selectedStages,
  productSelections,
  stageTypes,
  client,
  cropType,
}) => {
  const calculateTotal = (product) => {
    if (!product.quantity || !product.price) return 0;

    const quantity = Number(product.quantity);
    const minimunQuantity = Number(product.productMinimumUnit);
    const price = Number(product.price);
    const profitPercentage = Number(product.profitPercentage || 0);

    // Calcular el error sin truncar decimales
    const error =
      (Number(product.quantity) * Number(product.productError || 0)) / 100;

    console.log("Error:", error);
    console.log("Quantity:", quantity);

    // Calcular el mínimo múltiplo
    const minimunQuantityTotal =
      Math.ceil((quantity + error) / minimunQuantity) * minimunQuantity;

    console.log("Minimum Quantity Total:", minimunQuantityTotal);

    // Calcular subtotal y ganancia
    const subtotal = minimunQuantityTotal * price;
    const profit = subtotal * (profitPercentage / 100);

    const total = Number((subtotal + profit).toFixed(2));

    console.log("Total:", total);
    return total;
  };

  const totalAmount = useMemo(() => {
    return productSelections.reduce((total, product) => {
      return total + calculateTotal(product);
    }, 0);
  }, [productSelections]);

  return (
    <div className="budget-preview">
      <h5 className="mb-4 text-start">Previsualización del Presupuesto</h5>

      {/* Información General */}
      <section className="mb-4 text-start">
        <div className="card" style={{ border: "#004077" }}>
          <div
            className="card-header text-white"
            style={{ backgroundColor: "#004077" }}
          >
            <h6 className="mb-0">Información General</h6>
          </div>
          <div className="card-body bg-black text-white">
            <Row>
              <Col md={4}>
                <p>
                  <strong>Cliente:</strong> {client?.business_name}
                </p>
                <p>
                  <strong>Tipo de Cultivo:</strong> {cropType?.name}
                </p>
                <p>
                  <strong>Fecha de Entrega:</strong> {budgetData.delivery_date}
                </p>
              </Col>
              <Col md={4}>
                <p>
                  <strong>Sistema de Riego:</strong>{" "}
                  {budgetData.irrigation_system_type}
                </p>
                <p>
                  <strong>Fuente de Agua:</strong> {budgetData.water_source}
                </p>
                <p>
                  <strong>Área Total:</strong> {budgetData.total_area} m²
                </p>
              </Col>
              <Col md={4}>
                <p>
                  <strong>Distancia entre Emisores:</strong>{" "}
                  {budgetData.distance_between_drippers} cm
                </p>
                <p>
                  <strong>Distancia entre Líneas:</strong>{" "}
                  {budgetData.distance_between_lines} cm
                </p>
                <p>
                  <strong>Distancia entre Plantas:</strong>{" "}
                  {budgetData.distance_between_plants} cm
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      {/* Productos por Etapa */}
      <section className="mb-4 text-start">
        <div className="card" style={{ border: "#004077" }}>
          <div
            className="card-header text-white"
            style={{ backgroundColor: "#004077" }}
          >
            <h6 className="mb-0">Productos por Etapa</h6>
          </div>
          <div className="card-body bg-black text-white">
            {selectedStages.map((stageUuid) => {
              const stage = stageTypes.find((s) => s.uuid === stageUuid);
              const stageProducts = productSelections.filter(
                (p) => p.stageUuid === stageUuid
              );
              const stageTotal = stageProducts.reduce(
                (total, product) => total + calculateTotal(product),
                0
              );

              return (
                <div key={stageUuid} className="mb-4">
                  <h6 className="text-start text-white mb-3">{stage?.name}</h6>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered table-hover table-dark custom-table">
                      <thead className="custom-table-header-mini">
                        <tr>
                          <th>Producto</th>
                          <th>Proveedor</th>
                          <th>Cantidad</th>
                          <th>Cant. con error</th>
                          <th>Cant. con error redondeada</th>
                          <th className="text-end">Precio Unit.</th>
                          <th className="text-end">Ganancia (%)</th>
                          <th className="text-end">Total</th>
                        </tr>
                      </thead>
                      <tbody className="custom-table-body-mini">
                        {stageProducts.map((product, index) => (
                          <tr key={index}>
                            <td>{product.productDescription}</td>
                            <td>{product.providerName}</td>
                            <td>{product.quantity}</td>
                            <td
                              style={{ width: "90px" }}
                              className="align-middle text-center"
                            >
                              {product.quantity
                                ? Math.ceil(
                                    Number(product.quantity) +
                                      (Number(product.quantity) *
                                        Number(product.productError || 0)) /
                                        100
                                  )
                                : "-"}
                            </td>
                            <td
                              style={{ width: "90px" }}
                              className="align-middle text-center"
                            >
                              {product.quantity
                                ? (() => {
                                    const quantityWithError =
                                      Number(product.quantity) +
                                      (Number(product.quantity) *
                                        Number(product.productError || 0)) /
                                        100;

                                    if (product.productMinimumUnit === 1) {
                                      return parseInt(quantityWithError);
                                    }

                                    const remainder =
                                      quantityWithError %
                                      product.productMinimumUnit;
                                    if (remainder === 0) {
                                      return quantityWithError;
                                    }
                                    return (
                                      Math.ceil(
                                        quantityWithError /
                                          product.productMinimumUnit
                                      ) * product.productMinimumUnit
                                    );
                                  })()
                                : "-"}
                            </td>
                            <td className="text-end">
                              ${Number(product.price).toFixed(2)}
                            </td>
                            <td className="text-end">
                              {product.profitPercentage}%
                            </td>
                            <td className="text-end">
                              ${calculateTotal(product).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={7} className="text-end">
                            <strong>Total de la Etapa:</strong>
                          </td>
                          <td className="text-end">
                            <strong>${stageTotal.toFixed(2)}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Resumen Final */}
      <section className="mb-4 text-start">
        <div className="card" style={{ border: "#004077" }}>
          <div
            className="card-header text-white"
            style={{ backgroundColor: "#004077" }}
          >
            <h6 className="mb-0">Resumen Final</h6>
          </div>
          <div className="card-body bg-black text-white">
            <Row>
              <Col md={6}>
                <p>
                  <strong>Tiempo Total de Riego por Día:</strong>{" "}
                  {budgetData.total_irrigation_time_per_day} min
                </p>
                <p>
                  <strong>Caudal Requerido:</strong>{" "}
                  {budgetData.required_flow_rate} l/h
                </p>
                <p>
                  <strong>Presión Requerida:</strong>{" "}
                  {budgetData.required_pressure} bar
                </p>
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-end"
              >
                <div className="text-end">
                  <h4 className="mb-0">Total del Presupuesto:</h4>
                  <h3 className="text-success mb-0">
                    ${totalAmount.toFixed(2)}
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BudgetPreview;
