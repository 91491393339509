import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStageTypes,
  selectStageTypes,
  selectStageTypesError,
  selectStageTypesLoading,
  selectStageTypeById,
  clearStageType,
  fetchStageType,
} from "../../redux/reducers/stageTypesReducer";
import { selectLoggedUserProfile } from "../../redux/reducers/authReducer";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import SearchBar from "../../components/Searchbar";
import "../styles/Categories.css";
import NewStageTypeModal from "./NewStageTypeModal";
import EditStageTypeModal from "./EditStageTypeModal";
import DeleteStageTypeModal from "./DeleteStageTypeModal";
import { FaPlus, FaBan } from "react-icons/fa";
import { MdOutlineStairs } from "react-icons/md";

const StageTypes = () => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const loading = useSelector(selectStageTypesLoading);
  const stageTypes = useSelector(selectStageTypes);
  const error = useSelector(selectStageTypesError);
  const stageType = useSelector(selectStageTypeById);
  const profilesAuth = useSelector(selectLoggedUserProfile);

  useEffect(() => {
    dispatch(fetchStageTypes(null));
  }, [dispatch]);

  const hasManageStageTypesAccess = profilesAuth?.some((profile) =>
    profile.accesses?.some((access) => access.name === "manage_stage_types")
  );

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewEntityModal, setShowNewEntityModal] = useState(false);

  const handleEditOpen = (stage_type_uuid) => {
    dispatch(fetchStageType(stage_type_uuid));
    setShowEditModal(true);
  };

  const handleEditClose = () => {
    dispatch(clearStageType());
    setShowEditModal(false);
  };

  const handleDeleteOpen = (stage_type_uuid) => {
    dispatch(fetchStageType(stage_type_uuid));
    setShowDeleteModal(true);
  };

  const handleDeleteClose = (stage_type_uuid) => {
    dispatch(clearStageType());
    setShowDeleteModal(false);
  };

  const handleNewEntityOpen = () => {
    setShowNewEntityModal(true);
  };

  const handleNewEntityClose = () => {
    setShowNewEntityModal(false);
  };

  //Searchbar
  const handleSearch = (text) => {
    dispatch(fetchStageTypes(text));
  };

  return (
    <Container className="pt-4 pb-4">
      <Row>
        <Col>
          <Container className="pb-4 align-items-center">
            <Row className="align-items-center">
              <Col sm="3" md="3" lg="3">
                <h1 style={{ marginBottom: 0 }}>Etapas</h1>
              </Col>
              <Col sm="7" md="7" lg="7">
                <SearchBar onSearch={handleSearch} parameterName={"nombre"} />
              </Col>
              {hasManageStageTypesAccess && (
                <Col sm="2" md="2" lg="2">
                  <Button
                    variant="primary"
                    className="custom-button d-flex align-items-center justify-content-center"
                    onClick={handleNewEntityOpen}
                  >
                    <FaPlus />
                    <MdOutlineStairs size={30} style={{ marginLeft: "10px" }} />
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : stageTypes.length === 0 ? (
            <p>No se encontraron etapas.</p>
          ) : (
            <>
              <Table
                striped
                bordered
                hover
                variant="dark"
                className="custom-table"
              >
                <thead>
                  <tr>
                    <th className="custom-table-header">Nombre</th>
                    <th className="custom-table-header text-center">Código</th>
                    <th className="custom-table-header text-center">Órden</th>
                    {hasManageStageTypesAccess && (
                      <th
                        style={{ textAlign: "center", width: "120px" }}
                        className="custom-table-header"
                      >
                        Acciones
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {stageTypes.map((stageType) => (
                    <tr key={stageType.uuid}>
                      {console.log(stageType)}
                      <td>{stageType.name}</td>
                      <td className="text-center">{stageType.code}</td>
                      <td className="text-center">{stageType.order}</td>
                      {hasManageStageTypesAccess && stageType.can_update ? (
                        <td style={{ textAlign: "center" }}>
                          <Button
                            variant="warning"
                            size="sm"
                            className="me-2"
                            onClick={() => handleEditOpen(stageType.uuid)}
                          >
                            <PencilSquare />
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteOpen(stageType.uuid)}
                          >
                            <Trash />
                          </Button>
                        </td>
                      ) : (
                        <td className="text-center">
                          <FaBan />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}

          {/*POPUPS*/}
          <NewStageTypeModal
            showNewEntityModal={showNewEntityModal}
            handleClose={handleNewEntityClose}
            handleSearch={handleSearch}
          />
          <EditStageTypeModal
            showEditModal={showEditModal}
            handleClose={handleEditClose}
            stageType={stageType}
            handleSearch={handleSearch}
          />
          <DeleteStageTypeModal
            showDeleteModal={showDeleteModal}
            handleClose={handleDeleteClose}
            stageType={stageType}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StageTypes;
