// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import profileReducer from './reducers/profileReducer';
import accessesReducer from './reducers/accessesReducer';
import usersReducer from './reducers/usersReducer';
import clientsReducer from './reducers/clientsReducer';
import providersReducer from './reducers/providersReducer';
import productsReducer from './reducers/productsReducer';
import categoriesReducer from './reducers/categoriesReducer';
import pricesReducer from './reducers/pricesReducer';
import cropTypesReducer from './reducers/cropTypesReducer';
import stageTypesReducer from './reducers/stageTypesReducer';
import budgetsReducer from './reducers/budgetsReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    accesses: accessesReducer,
    users: usersReducer,
    clients: clientsReducer,
    providers: providersReducer,
    products: productsReducer,
    categories: categoriesReducer,
    prices: pricesReducer,
    cropTypes: cropTypesReducer,
    stageTypes: stageTypesReducer,
    budgets: budgetsReducer
  },
});

export default store;
