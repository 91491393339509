import { createSlice } from '@reduxjs/toolkit';
import { withAuthentication } from '../middlewares';
import { getPrices, validatePrices, importPrices } from '../actions/pricesThunks';

const initialState = {
  data: [],
  validationResult: null,
  loading: false,
  error: null,
  successMessage: null,
  errorMessage: null,
};

const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    // Obtener precios
    fetchPricesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPricesSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchPricesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // Validar precios
    validatePricesStart(state) {
      state.loading = true;
      state.error = null;
    },
    validatePricesSuccess(state, action) {
      state.loading = false;
      state.validationResult = action.payload;
      state.successMessage = 'Validación exitosa';
    },
    validatePricesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.errorMessage = 'Error en la validación';
    },

    clearValidationResult(state) {
      state.validationResult = null;
      state.error = null;
      state.successMessage = null;
      state.errorMessage = null;
    },

    // Importar precios
    importPricesStart(state) {
      state.loading = true;
      state.error = null;
    },
    importPricesSuccess(state, action) {
      state.loading = false;
      state.successMessage = 'Importación exitosa';
      state.errorMessage = null;
    },
    importPricesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.errorMessage = 'Error en la importación';
    },

    // Limpiar mensajes
    clearMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
  },
});

export const fetchPrices = withAuthentication((authToken) => async (dispatch) => {
  dispatch(fetchPricesStart());
  try {
    const prices = await getPrices(authToken);
    dispatch(fetchPricesSuccess(prices));
  } catch (error) {
    dispatch(fetchPricesFailure(error.message));
  }
});

export const validatePricesThunk = withAuthentication((authToken, file, uuidProvider) => async (dispatch) => {
  dispatch(validatePricesStart());
  try {
    const validationResult = await validatePrices(authToken, file, uuidProvider);
    dispatch(validatePricesSuccess(validationResult));
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || // Mensaje del backend
      error.message || // Mensaje del objeto error
      'Error desconocido'; // Fallback genérico

    dispatch(validatePricesFailure(errorMessage)); // Actualiza el estado con el error
    throw new Error(errorMessage); // Lanza el error para capturarlo en el componente
  }
});

export const importPricesThunk = withAuthentication((authToken, prices, uuidProvider) => async (dispatch) => {
  dispatch(importPricesStart());
  try {
    const response = await importPrices(authToken, prices, uuidProvider);
    dispatch(importPricesSuccess(response));
  } catch (error) {
    dispatch(importPricesFailure(error.message));
  }
});

export const {
  fetchPricesStart,
  fetchPricesSuccess,
  fetchPricesFailure,
  validatePricesStart,
  validatePricesSuccess,
  validatePricesFailure,
  clearValidationResult,
  importPricesStart,
  importPricesSuccess,
  importPricesFailure,
  clearMessages,
} = pricesSlice.actions;

export const selectPrices = (state) => state.prices.data;
export const selectPricesLoading = (state) => state.prices.loading;
export const selectPricesError = (state) => state.prices.error;
export const selectPricesSuccessMessage = (state) => state.prices.successMessage;
export const selectPricesErrorMessage = (state) => state.prices.errorMessage;
export const selectPricesValidationResult = (state) => state.prices.validationResult;

export default pricesSlice.reducer;
