import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editProvider, editCloseMessage, selectProvidersErrorMessage, selectProvidersSuccessMessage } from '../../redux/reducers/providersReducer';

const EditProviderModal = ({ showEditModal, handleClose, handleSearch, provider }) => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();

  //Mensaje de exito o error
  const successMessage = useSelector(selectProvidersSuccessMessage);
  const errorMessage = useSelector(selectProvidersErrorMessage);

  const handleCloseMessage = () => {
    handleClose();
    setFormData({
      name: '',
      phone: '',
      is_national: true
    })
    dispatch(editCloseMessage());
    handleSearch();
  }

  //Nombre y descripcion
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    is_national: true
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    if (provider) {
      setFormData({
        name: provider.name,
        phone: provider.phone,
        is_national: provider.is_national,
        type: provider.is_national ? 'national' : 'international', // Automatically set type
      });
      setInitialFormData({
        name: provider.name,
        phone: provider.phone,
        is_national: provider.is_national,
        type: provider.is_national ? 'national' : 'international',
      });
    }
  }, [provider]);

  //Cambios de formulario
  const [initialFormData, setInitialFormData] = useState();
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (initialFormData) {
      const hasChanges = JSON.stringify(initialFormData) !== JSON.stringify(formData);
      setIsChanged(hasChanges);
    }
  }, [formData, initialFormData]);

  const handleTypeChange = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      type: value,
      is_national: value === 'national',
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(editProvider(provider.uuid, formData));
  };

  return (
    <div>
      <Modal show={showEditModal} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Editar proveedor</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: '8px' }}>
          <form style={{ display: 'contents' }} onSubmit={handleSubmit}>
            <div>
              <Row className='pb-2'>
                <Col sm="5" md="5" lg="5">
                  <InputGroup>
                    <InputGroup.Text>Nombre:</InputGroup.Text>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Nombre profesional..."
                      value={formData.name}
                      onChange={handleFormChange}
                      style={{ marginTop: 0, height: '100%' }}
                      required
                    />
                  </InputGroup>
                </Col>
                <Col sm="4" md="4" lg="4">
                  <InputGroup>
                    <InputGroup.Text>Teléfono:</InputGroup.Text>
                    <Form.Control
                      name="phone"
                      type="number"
                      placeholder="Número de teléfono..."
                      value={formData.phone}
                      onChange={handleFormChange}
                      style={{ marginTop: 0, height: '100%' }}
                      required
                    />
                  </InputGroup>
                </Col>
                <Col sm="3" md="3" lg="3">
                  <InputGroup>
                    <Form.Select
                      value={formData.type} // Value tied to formData.type
                      onChange={handleTypeChange}
                      style={{
                        marginTop: 0,
                        height: '100%',
                        paddingRight: '1.5rem',
                      }}
                    >
                      <option value="national">Nacional</option>
                      <option value="international">Internacional</option>
                    </Form.Select>
                  </InputGroup>
                </Col>
              </Row>
            </div>
            <Modal.Footer>
              {!isChanged && (
                <p className="form-text text-muted">
                  Debe realizar cambios para guardar
                </p>
              )}
              <Button variant="primary" style={{ marginTop: '0px' }}
                onClick={handleSubmit}
                className={`custom-button ${!isChanged
                  ? 'custom-button-disabled'
                  : ''
                  }`}>
                Guardar cambios
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
        <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
          <Modal.Header closeButton>
            <Modal.Title>{errorMessage ? 'Error en la modificación' : 'Modificación exitosa'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage || errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </Modal >
    </div>
  );
}

export default EditProviderModal;