import React from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";

const ChangePasswordModal = ({
  showModal,
  handleCloseModal,
  formData,
  handleFormChange,
  handlePasswordChange,
  showAlert,
  alertColor,
  alertMessage,
}) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>Cambiar Contraseña</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <Form
          onSubmit={handlePasswordChange}
          className="w-100"
          style={{ maxWidth: "600px", margin: "0 auto" }}
        >
          <Form.Group controlId="oldPassword" className="modal-input">
            <Form.Label className="fw-bold">Contraseña actual</Form.Label>
            <Form.Control
              type="password"
              name="oldPassword"
              value={formData.oldPassword}
              onChange={handleFormChange}
              placeholder="Ingresa tu contraseña actual"
              className="p-3"
              style={{ width: "100%" }}
              required
            />
          </Form.Group>

          <Form.Group controlId="newPassword" className="modal-input">
            <Form.Label className="fw-bold">Nueva contraseña</Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleFormChange}
              placeholder="Ingresa tu nueva contraseña"
              className="p-3"
              required
            />
          </Form.Group>

          <Form.Group controlId="repeatNewPassword" className="modal-input">
            <Form.Label className="fw-bold">Repetir nueva contraseña</Form.Label>
            <Form.Control
              type="password"
              name="repeatNewPassword"
              value={formData.repeatNewPassword}
              onChange={handleFormChange}
              placeholder="Repite tu nueva contraseña"
              className="p-3"
              required
            />
          </Form.Group>

          {showAlert && (
            <Alert
              variant={alertColor}
              dismissible
              className="mt-4"
            >
              {alertMessage}
            </Alert>
          )}

          <div className="d-grid">
            <Button variant="dark" type="submit">
              Cambiar contraseña
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
