export const getCropTypesWithFilters = async (token, filters = {}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_HOST}/crop_types/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({filters}),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const getCropTypeById = async (token, cropTypeUuid) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_HOST}/crop_types/${cropTypeUuid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const createCropType = async (token, formData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_HOST}/crop_types/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const editCropTypeById = async (token, cropTypeUuid, formData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_HOST}/crop_types/${cropTypeUuid}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const deleteCropTypeById = async (token, cropTypeUuid) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_HOST}/crop_types/${cropTypeUuid}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
