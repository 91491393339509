import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { editCloseMessage, editUser, selectErrorMessage, selectSuccessMessage } from '../../redux/reducers/usersReducer';
import { fetchProfiles, selectProfiles } from '../../redux/reducers/profileReducer';

const EditUserModal = ({ showEditModal, handleClose, user }) => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();

  //Mensaje de exito o error
  const successMessage = useSelector(selectSuccessMessage);
  const errorMessage = useSelector(selectErrorMessage);

  //Formulario
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    profiles: []
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name,
        profiles: user.profiles?.map(profile => profile.uuid) || []
      });
      setInitialFormData({
        name: user.name,
        profiles: user.profiles?.map(profile => profile.uuid) || [],
      });

    }
  }, [user]);

  //Cambios de formulario
  const [initialFormData, setInitialFormData] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const hasChanges = JSON.stringify(initialFormData) !== JSON.stringify(formData);
    setIsChanged(hasChanges);
  }, [formData, initialFormData]);

  //Perfiles
  const profiles = useSelector(selectProfiles);
  useEffect(() => {
    dispatch(fetchProfiles(null));
  }, [dispatch]);

  const chunkArray = (arr, chunkSize) => {
    const arrCopy = [...arr];
    arrCopy.sort((a, b) => a.name.localeCompare(b.name));

    const chunks = [];
    for (let i = 0; i < arrCopy.length; i += chunkSize) {
      chunks.push(arrCopy.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkedProfiles = chunkArray(profiles, 4);

  const handleProfileCheckboxChange = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setFormData(prevFormData => ({
        ...prevFormData,
        profiles: [...prevFormData.profiles, id]
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        profiles: prevFormData.profiles.filter(profileId => profileId !== id)
      }));
    }
  };

  //Acciones
  const handleFormChange = (event) => {
    let { name, value } = event.target;
    if (name === 'phone' || name === 'dni') {
      value = value.replace(/\D/g, ''); // Correctly format value
    }
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCloseMessage = () => {
    dispatch(editCloseMessage());
    setInitialFormData(null); // Reset initial data
    setFormData({
      name: '',
      email: '',
      profiles: []
    });
    handleClose();
  }

  const handleSubmit = () => {
    dispatch(editUser(user.uuid, formData))
  };

  return (
    <div>
      <Modal show={showEditModal} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title><strong>Editar usuario</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: '8px', marginRight: '8px' }}>
          <form style={{ display: 'contents' }}>
            <div>
              {user && (
                <>
                  <Row className='pb-4'>
                    <Col sm='6' md='6' lg='6'>
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 'bold' }}>Nombre y apellido</Form.Label>
                        <Form.Control
                          name='name'
                          type="text"
                          placeholder="Nombre..."
                          value={formData.name}
                          onChange={handleFormChange}
                          style={{ marginTop: 0, height: '100%' }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <>
                    <h4 className='pt-4'>Perfiles del usuario</h4>
                    <p className='pb-2'>Seleccione los perfiles asociados al usuario</p>
                    {formData.profiles && (
                      chunkedProfiles.map((chunk, index) => (
                        <Row key={index} className="mb-3" style={{ marginLeft: '1rem' }}>
                          {chunk.map(profile => (
                            <Col key={profile.uuid} md={3} className="pb-3">
                              <Form.Check
                                type="checkbox"
                                id={profile.uuid}
                                label={profile.name}
                                onChange={handleProfileCheckboxChange}
                                checked={formData.profiles.includes(profile.uuid)}
                              />
                            </Col>
                          ))}
                        </Row>
                      )
                      ))}
                  </>

                </>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          {!isChanged && (
            <p className="form-text text-muted">
              Debe realizar cambios para guardar
            </p>
          )}
          <Button variant="primary" style={{ marginTop: '0px' }}
            onClick={handleSubmit}
            className={`custom-button ${!isChanged
              ? 'custom-button-disabled'
              : ''
              }`}>
            Guardar cambios
          </Button>
        </Modal.Footer>
        <Modal show={!!successMessage || !!errorMessage} onHide={handleCloseMessage}>
          <Modal.Header closeButton>
            <Modal.Title>{errorMessage ? 'Error en la modificación' : 'Modificación exitosa'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage || errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMessage}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </Modal >
    </div>
  );
}

export default EditUserModal;