import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Pagination,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import {
  fetchProviders,
  selectProviders,
  selectProvidersLoading,
  selectProvidersError,
} from "../../redux/reducers/providersReducer";
import {
  fetchPrices,
  validatePricesThunk,
  importPricesThunk,
  selectPricesLoading,
  selectPricesValidationResult,
  selectPricesError,
  clearValidationResult,
  clearMessages,
  selectPrices,
} from "../../redux/reducers/pricesReducer";
import ValidatePricesModal from "./ValidatePricesModal";
import { FaDownload, FaEyeSlash, FaUpload } from "react-icons/fa";
import { BiSpreadsheet } from "react-icons/bi";
import { MdOutlineExpandLess } from "react-icons/md";
import "../styles/Prices.css";
import template from "../../assets/templates/template_precios.xlsx";

const Prices = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectProvidersLoading);
  const pricesLoading = useSelector(selectPricesLoading);
  const providers = useSelector(selectProviders);
  const prices = useSelector(selectPrices);
  const error = useSelector(selectProvidersError);
  const validationResult = useSelector(selectPricesValidationResult);
  const validationError = useSelector(selectPricesError);

  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef(null);
  const itemsPerPage = 8;

  // Datos de la tabla con 10 productos más
  const [currentPrices, setCurrentPrices] = useState([]);

  // Calcular el índice de inicio y fin para la paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = currentPrices.slice(indexOfFirstItem, indexOfLastItem);

  // Total de páginas
  const totalPages = Math.ceil(prices.length / itemsPerPage);

  // Calcular el rango de páginas a mostrar en la paginación
  const maxVisiblePages = 3; // Número máximo de páginas visibles
  const [visiblePages, setVisiblePages] = useState([]);

  useEffect(() => {
    const calculateVisiblePages = () => {
      const midPage = Math.ceil(maxVisiblePages / 2);
      let startPage = currentPage - midPage + 1;
      let endPage = currentPage + midPage - 1;

      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(maxVisiblePages, totalPages);
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      setVisiblePages(
        Array.from({ length: endPage - startPage + 1 }, (_, i) => i + startPage)
      );
    };

    calculateVisiblePages();
  }, [currentPage, totalPages, maxVisiblePages]);

  useEffect(() => {
    dispatch(fetchProviders(""));
  }, [dispatch]);

  useEffect(() => {
    // Limpia errores previos al cargar el modal de éxito
    if (validationResult && !validationError) {
      dispatch(clearMessages());
    }
  }, [validationResult, validationError, dispatch]);

  useEffect(() => {
    if (validationError) {
      alert(`Error: ${validationError}`);
    }
  }, [validationError]);

  useEffect(() => {
    dispatch(fetchPrices(""));
  }, [dispatch]);

  useEffect(() => {
    if (prices.length > 0) {
      setCurrentPrices(prices);
    }
  }, [prices]);

  const handleProviderChange = (event) => {
    const providerUuid = event.target.value;
    const provider = providers.find((p) => p.uuid === providerUuid);
    setSelectedProvider(provider); // Almacena el objeto completo del proveedor
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile || !selectedProvider) {
      alert("Por favor, selecciona un archivo y un proveedor antes de enviar.");
      return;
    }

    const uuidProvider = selectedProvider.uuid;

    // Limpia errores y resultados previos
    dispatch(clearValidationResult());

    // Ejecuta la validación
    try {
      await dispatch(validatePricesThunk(selectedFile, uuidProvider));
    } catch (error) {
      return;
    }

    // Muestra el modal si no hay errores
    if (!validationError) {
      setShowModal(true);
    }
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  // Función para manejar la ordenación
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });

    // Crea una copia del array `currentPrices` y ordena
    const sortedPrices = [...currentPrices].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setCurrentPrices(sortedPrices);
  };

  // Función para agregar el indicador de ordenación en el encabezado
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? " ▲" : " ▼";
    }
    return "";
  };

  // Cambiar página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpdatePrices = async (prices) => {
    await dispatch(importPricesThunk(prices, selectedProvider.uuid));
    setShowModal(false);

    dispatch(fetchPrices(""));
  };

  return (
    <Container className="pt-4 pb-4">
      <Row>
        <Col>
          <Container className="pb-4 align-items-center">
            <Row className="align-items-center">
              <Col sm="6" md="6" lg="6">
                <h1 style={{ marginBottom: 0 }}>Gestión de Precios</h1>
              </Col>
            </Row>
          </Container>

          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : providers.length === 0 ? (
            <p>No se encontraron proveedores</p>
          ) : (
            <Container className="pb-4">
              <Button
                variant="primary"
                className="custom-button d-flex align-items-center justify-content-center"
                onClick={toggleFormVisibility}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                }}
              >
                {isFormVisible ? (
                  <>
                    Ocultar formulario
                    <FaEyeSlash size={20} style={{ marginLeft: "8px" }} />
                    <MdOutlineExpandLess
                      size={20}
                      style={{ marginLeft: "5px" }}
                    />
                  </>
                ) : (
                  <>
                    Cargar nueva lista de precios
                    <BiSpreadsheet size={20} style={{ marginLeft: "8px" }} />
                    <FaUpload size={20} style={{ marginLeft: "5px" }} />
                  </>
                )}
              </Button>

              {/* Animación del formulario */}
              <CSSTransition
                in={isFormVisible}
                timeout={800} // Duración aumentada para suavidad
                classNames="form-transition"
                unmountOnExit
                nodeRef={formRef}
              >
                <div ref={formRef} className="animated-form">
                  <Row className="align-items-center mb-3">
                    <Col sm="2" md="2" lg="2">
                      <Form.Group>
                        <Form.Label style={{ color: "white" }}>
                          Descargar template
                        </Form.Label>
                        <Button
                          variant="secondary"
                          className="d-flex align-items-center custom-hover-button"
                          style={{
                            backgroundColor: "#36454F",
                            color: "white",
                            border: "none",
                            padding: "0.8rem",
                            paddingLeft: "1.2rem",
                            justifyContent: "center",
                          }}
                          href={template}
                          download
                        >
                          <FaDownload
                            className="hover-icon"
                            style={{ marginRight: "8px" }}
                          />
                          <BiSpreadsheet
                            className="hover-icon"
                            style={{ marginRight: "8px", fontSize: "1.5rem" }}
                          />
                        </Button>
                      </Form.Group>
                    </Col>
                    <Col sm="4" md="4" lg="4">
                      <Form.Group>
                        <Form.Label style={{ color: "white" }}>
                          Seleccionar proveedor
                        </Form.Label>
                        <Form.Select
                          value={selectedProvider?.uuid || ""}
                          onChange={handleProviderChange}
                          style={{
                            backgroundColor: "#36454F",
                            color: "white",
                            border: "none",
                            padding: "0.8rem",
                            paddingLeft: "1.2rem",
                          }}
                        >
                          <option value="">Selecciona un proveedor</option>
                          {providers.map((provider) => (
                            <option key={provider.uuid} value={provider.uuid}>
                              {provider.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm="6" md="6" lg="6">
                      <Form.Group>
                        <Form.Label style={{ color: "white" }}>
                          Seleccionar archivo
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept=".xlsx"
                          onChange={handleFileChange}
                          style={{
                            backgroundColor: "#36454F",
                            color: "white",
                            border: "none",
                            padding: "0.8rem",
                            paddingLeft: "1.2rem",
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        variant="primary"
                        className={`custom-button ${
                          !selectedProvider || !selectedFile
                            ? "custom-button-disabled"
                            : ""
                        }`}
                        style={{
                          width: "100%",
                        }}
                        onClick={handleFileUpload}
                        disabled={
                          !selectedProvider || !selectedFile || pricesLoading
                        }
                      >
                        {pricesLoading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ marginRight: "8px" }}
                            />
                            Validando...
                          </>
                        ) : (
                          "Validar archivo"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CSSTransition>

              {/* Tabla de precios actuales */}
              <Table
                striped
                bordered
                hover
                variant="dark"
                className="custom-table"
              >
                <thead>
                  <tr>
                    <th
                      onClick={() => handleSort("product_code")}
                      className="custom-table-header"
                    >
                      Código {getSortIndicator("product_code")}
                    </th>
                    <th
                      onClick={() => handleSort("product_description")}
                      className="custom-table-header"
                    >
                      Descripción del producto{" "}
                      {getSortIndicator("product_description")}
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      onClick={() => handleSort("product_unit")}
                      className="custom-table-header"
                    >
                      Unidad {getSortIndicator("product_unit")}
                    </th>
                    <th
                      onClick={() => handleSort("provider_name")}
                      className="custom-table-header"
                    >
                      Proveedor {getSortIndicator("provider_name")}
                    </th>
                    <th
                      onClick={() => handleSort("price")}
                      className="custom-table-header"
                    >
                      Precio {getSortIndicator("price")}
                    </th>
                    <th
                      onClick={() => handleSort("updated_at")}
                      className="custom-table-header"
                    >
                      Última act. {getSortIndicator("updated_at")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((price, index) => (
                    <tr key={index} style={{ fontSize: 14 }}>
                      <td>{price.product_code}</td>
                      <td>{price.product_description}</td>
                      <td style={{ textAlign: "center" }}>
                        {price.product_unit}
                      </td>
                      <td>{price.provider_name}</td>
                      <td>
                        {price.price
                          ? `$ ${Number(price.price).toLocaleString("es-AR", {
                              minimumFractionDigits: 6,
                              maximumFractionDigits: 6,
                            })}`
                          : "Sin precio"}
                      </td>
                      <td>
                        {price.updated_at
                          ? new Intl.DateTimeFormat("es-AR", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(new Date(price.updated_at))
                          : "Sin fecha"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Paginación */}
              <Pagination className="custom-pagination">
                {visiblePages.map((pageNumber) => (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </Pagination.Item>
                ))}
              </Pagination>
            </Container>
          )}
        </Col>
      </Row>

      {/* Modal para mostrar los resultados */}
      <ValidatePricesModal
        showModal={showModal}
        validationResult={validationResult}
        selectedProvider={selectedProvider}
        handleCloseModal={handleCloseModal}
        handleUpdatePrices={handleUpdatePrices}
      />
    </Container>
  );
};

export default Prices;
