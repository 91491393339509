export const getPrices = async (token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/prices/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const validatePrices = async (token, file, uuid_provider) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('uuid_provider', uuid_provider);

    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/prices/validate`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}` // No configures 'Content-Type' manualmente
      },
      body: formData
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error desconocido');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const importPrices = async (token, prices, uuid_provider) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/prices/import`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ prices, uuid_provider }) // Enviamos el array de precios y el uuid_provider
    });

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};