import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import Stepper from "react-stepper-horizontal";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchStageTypes,
  selectStageTypes,
  selectStageTypesLoading,
  selectStageTypesError,
  processCsvFile,
} from "../../redux/reducers/stageTypesReducer";
import {
  fetchClients,
  selectClients,
  selectClientsLoading,
  selectClientsError,
} from "../../redux/reducers/clientsReducer";
import {
  fetchCropTypes,
  selectCropTypes,
  selectCropTypesLoading,
  selectCropTypesError,
} from "../../redux/reducers/cropTypesReducer";
import { searchProductsByCodeThunk } from "../../redux/reducers/productsReducer";
import { createNewBudget } from "../../redux/reducers/budgetsReducer";

import ProductSearchModal from "./ProductSearchModal";
import BudgetPreview from "./BudgetPreview";
import CSVUploadModal from "./CSVUploadModal";

import { AiOutlineFileExcel } from "react-icons/ai";

const NewBudgetModal = ({ showNewEntityModal, handleClose }) => {
  const dispatch = useDispatch();

  // Estados para manejar las etapas y pasos
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedStages, setSelectedStages] = useState([]);
  const [budgetData, setBudgetData] = useState({
    uuid_client: "",
    uuid_crop_type: "",
    delivery_date: "",
    distance_between_drippers: "",
    distance_between_lines: "",
    distance_between_plants: "",
    irrigation_system_type: "",
    water_source: "",
    total_area: "",
    operations_number: "",
    time_per_operation: "",
    total_irrigation_time_per_day: "",
    required_flow_rate: "",
    required_pressure: "",
    instantaneous_water_depth: "",
    total_water_depth: "",
    total_amount: "",
    first_stage_area: "",
    laterals_per_line: "",
  });

  const [productSelections, setProductSelections] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedStageUuid, setSelectedStageUuid] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [generalProfitPercentage, setGeneralProfitPercentage] = useState(0);
  const [showCSVModal, setShowCSVModal] = useState(false);

  // Datos de Redux
  const stageTypes = useSelector(selectStageTypes);
  const stageTypesLoading = useSelector(selectStageTypesLoading);
  const stageTypesError = useSelector(selectStageTypesError);

  const cropTypes = useSelector(selectCropTypes);
  const cropTypesLoading = useSelector(selectCropTypesLoading);
  const cropTypesError = useSelector(selectCropTypesError);

  const clients = useSelector(selectClients);
  const clientsLoading = useSelector(selectClientsLoading);
  const clientsError = useSelector(selectClientsError);

  // Cargar datos iniciales
  useEffect(() => {
    if (showNewEntityModal) {
      dispatch(fetchStageTypes());
      dispatch(fetchCropTypes());
      dispatch(fetchClients());
    }
  }, [dispatch, showNewEntityModal]);

  // Manejar selección de etapas
  const handleStageSelection = (stageUuid) => {
    setSelectedStages((prev) =>
      prev.includes(stageUuid)
        ? prev.filter((uuid) => uuid !== stageUuid)
        : [...prev, stageUuid]
    );
  };

  // Manejar cambios en el formulario de presupuesto
  const handleBudgetChange = (event) => {
    const { name, value } = event.target;
    setBudgetData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearch = async (searchValue, stageUuid) => {
    if (!searchValue.trim()) return;

    try {
      const response = await dispatch(searchProductsByCodeThunk(searchValue));
      setSearchResults(response.product_providers);
      setSelectedStageUuid(stageUuid);
      setShowSearchModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotal = (product) => {
    if (!product.quantity || !product.price) return 0;

    const quantity = Number(product.quantity);
    const price = Number(product.price);
    const profitPercentage = Number(product.profitPercentage || 0);

    // Calcular el error sin truncar decimales
    const error =
      (Number(product.quantity) * Number(product.productError || 0)) / 100;

    // Calcular la cantidad total con el error y redondearla al múltiplo mínimo (si aplica)
    const minimunQuantity = Number(product.productMinimumUnit || 1); // Suponer 1 si no se provee
    const quantityWithError = quantity + error;
    const minimunQuantityTotal =
      Math.ceil(quantityWithError / minimunQuantity) * minimunQuantity;

    // Calcular subtotal y ganancia
    const subtotal = minimunQuantityTotal * price;
    const profit = subtotal * (profitPercentage / 100);

    return Number((subtotal + profit).toFixed(2)); // Redondear al final
  };

  const handleProductSelect = (newProduct) => {
    setProductSelections((prev) => [
      ...prev,
      {
        ...newProduct,
        profitPercentage: generalProfitPercentage,
      },
    ]);
    setShowSearchModal(false);
    setSearchResults([]);
  };

  // Enviar el presupuesto
  const handleSubmit = async () => {
    // Mapear los datos básicos del presupuesto
    const mappedBudgetData = {
      uuid_client: budgetData.uuid_client,
      // uuid_user: "54f42bf9-4dc6-4ac8-b6dc-29f900a9fe0c", //TODO: ELIMINAR ESTO
      uuid_crop_type: budgetData.uuid_crop_type,
      delivery_date: budgetData.delivery_date,
      distance_between_drippers:
        parseFloat(budgetData.distance_between_drippers) || 0,
      distance_between_lines: parseInt(budgetData.distance_between_lines) || 0,
      distance_between_plants:
        parseInt(budgetData.distance_between_plants) || 0,
      drip_discharge: 0, // Este campo no está en el formulario, se envía por defecto
      first_stage_area: parseInt(budgetData.first_stage_area) || 0,
      instantaneous_water_depth:
        parseFloat(budgetData.instantaneous_water_depth) || 0,
      irrigation_system_type: budgetData.irrigation_system_type,
      laterals_per_line: parseInt(budgetData.laterals_per_line) || 0,
      operations_number: parseInt(budgetData.operations_number) || 0,
      required_flow_rate: parseInt(budgetData.required_flow_rate) || 0,
      required_pressure: parseInt(budgetData.required_pressure) || 0,
      time_per_operation: parseFloat(budgetData.time_per_operation) || 0,
      total_amount: parseFloat(budgetData.total_amount) || 0,
      total_area: parseInt(budgetData.total_area) || 0,
      total_irrigation_time_per_day:
        parseInt(budgetData.total_irrigation_time_per_day) || 0,
      total_water_depth: parseFloat(budgetData.total_water_depth) || 0,
      water_source: budgetData.water_source,
      stages: [],
    };

    // Mapear las etapas y sus detalles
    mappedBudgetData.stages = selectedStages.map((stageUuid) => {
      // Filtrar los productos que corresponden a esta etapa
      const stageProducts = productSelections.filter(
        (product) => product.stageUuid === stageUuid
      );

      return {
        uuid_stage_type: stageUuid,
        stage_details: stageProducts.map((product) => ({
          uuid_product_provider: product.uuidProductProvider,
          price: parseFloat(product.price) || 0,
          profit: parseFloat((product.profitPercentage || 0) / 100),
          quantity: parseInt(product.quantity) || 0,
        })),
      };
    });

    // Aquí puedes agregar la lógica para enviar los datos al backend
    try {
      const response = await dispatch(createNewBudget(mappedBudgetData));
      handleClose();
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      handleClose();
      alert("Error al enviar los datos");
    } finally {
      setShowCSVModal(false);
    }
  };

  // Pasos del formulario
  const steps = [
    { title: "Seleccionar Etapas" },
    { title: "Datos del Presupuesto" },
    { title: "Seleccionar Productos" },
    { title: "Previsualización" },
  ];

  const handleFileUpload = async (formData) => {
    try {
      return new Promise((resolve) => {
        setTimeout(async () => {
          await dispatch(processCsvFile(formData));
          resolve();
        }, 2000);
      });
    } catch (error) {
      console.error("Error al procesar el archivo:", error);
    }
  };

  return (
    <Modal show={showNewEntityModal} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Nuevo Presupuesto</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px", width: "100%" }}>
        <div style={{ textAlign: "center", width: "100%" }}>
          <div style={{ marginBottom: "20px" }}>
            <Stepper
              steps={steps}
              activeStep={currentStep}
              circleTop={4}
              activeColor="#0077e0"
              completeColor="#004077"
              activeTitleColor="#FFFFFF"
              completeTitleColor="#FFFFFF"
              defaultBarColor="#000000"
              size={48}
              circleFontSize={20}
              titleFontSize={20}
              defaultTitleColor="#FFFFFF"
              barStyle="solid "
              lineMarginOffset={12}
              circleFontColor="#000000"
            />
            <hr style={{ marginTop: "40px" }} />
          </div>
          {currentStep === 0 && (
            <>
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="mb-0">Selecciona las etapas:</h5>
                <div className="d-flex gap-2">
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setSelectedStages(stageTypes.map((stage) => stage.uuid))
                    }
                  >
                    Seleccionar todos
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setSelectedStages([])}
                  >
                    Deseleccionar todos
                  </Button>
                </div>
              </div>
              {stageTypesLoading ? (
                <p>Cargando etapas...</p>
              ) : stageTypesError ? (
                <p>Error al cargar etapas: {stageTypesError}</p>
              ) : (
                <div>
                  <Row>
                    {[...stageTypes]
                      .sort((a, b) => a.order - b.order)
                      .map((stage) => (
                        <Col
                          key={stage.uuid}
                          xs={12}
                          sm={6}
                          md={4}
                          className="mb-4"
                        >
                          <Form.Check
                            type="checkbox"
                            label={`${stage.name}`}
                            checked={selectedStages.includes(stage.uuid)}
                            onChange={() => handleStageSelection(stage.uuid)}
                            style={{ textAlign: "left" }}
                          />
                        </Col>
                      ))}
                  </Row>
                </div>
              )}
            </>
          )}
          {currentStep === 1 && (
            <div className="w-100">
              <h5 className="mb-4 text-start">Datos del presupuesto</h5>

              {/* Información básica */}
              <section className="mb-4 text-start">
                <div className="card" style={{ border: "#004077" }}>
                  <div
                    className="card-header text-white"
                    style={{ backgroundColor: "#004077" }}
                  >
                    <h6 className="mb-0">Información general</h6>
                  </div>
                  <div className="card-body bg-black text-white">
                    <Row>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Cliente</Form.Label>
                          {clientsLoading ? (
                            <p>Cargando clientes...</p>
                          ) : clientsError ? (
                            <p>Error al cargar clientes: {clientsError}</p>
                          ) : (
                            <Select
                              options={clients.map((client) => ({
                                value: client.uuid,
                                label: client.business_name,
                              }))}
                              onChange={(option) =>
                                setBudgetData((prev) => ({
                                  ...prev,
                                  uuid_client: option.value,
                                }))
                              }
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  backgroundColor: "#ffffff",
                                  height: 50,
                                  minHeight: 50,
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  backgroundColor: "#f0f0f0",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#4CAF50"
                                    : state.isFocused
                                    ? "#e6e6e6"
                                    : "#ffffff",
                                  color: state.isSelected
                                    ? "#ffffff"
                                    : "#000000",
                                  padding: 15,
                                }),
                              }}
                            />
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Tipo de cultivo</Form.Label>
                          {cropTypesLoading ? (
                            <p>Cargando tipos de cultivos...</p>
                          ) : cropTypesError ? (
                            <p>
                              Error al cargar tipos de cultivos:{" "}
                              {cropTypesError}
                            </p>
                          ) : (
                            <Select
                              options={cropTypes.map((crop) => ({
                                value: crop.uuid,
                                label: crop.name,
                              }))}
                              onChange={(option) =>
                                setBudgetData((prev) => ({
                                  ...prev,
                                  uuid_crop_type: option.value,
                                }))
                              }
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  backgroundColor: "#ffffff",
                                  height: 50,
                                  minHeight: 50,
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  backgroundColor: "#f0f0f0",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#4CAF50"
                                    : state.isFocused
                                    ? "#e6e6e6"
                                    : "#ffffff",
                                  color: state.isSelected
                                    ? "#ffffff"
                                    : "#000000",
                                  padding: 15,
                                }),
                              }}
                            />
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group>
                          <Form.Label>Fecha de entrega</Form.Label>
                          <Form.Control
                            name="delivery_date"
                            type="date"
                            value={budgetData.delivery_date}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group>
                          <Form.Label>% General de Ganancia</Form.Label>
                          <Form.Control
                            type="number"
                            min="0"
                            step="0.01"
                            value={generalProfitPercentage}
                            onChange={(e) =>
                              setGeneralProfitPercentage(Number(e.target.value))
                            }
                            placeholder="Ingrese porcentaje"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>

              {/* Distancias */}
              <section className="mb-4 text-start">
                <div className="card" style={{ border: "#004077" }}>
                  <div
                    className="card-header text-white"
                    style={{ backgroundColor: "#004077" }}
                  >
                    <h6 className="mb-0">Distancias</h6>
                  </div>
                  <div className="card-body bg-black text-white">
                    <Row>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Distancia entre emisores (cm)</Form.Label>
                          <Form.Control
                            name="distance_between_drippers"
                            type="number"
                            step="0.01"
                            value={budgetData.distance_between_drippers}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Distancia entre líneas (cm)</Form.Label>
                          <Form.Control
                            name="distance_between_lines"
                            type="number"
                            value={budgetData.distance_between_lines}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Distancia entre plantas (cm)</Form.Label>
                          <Form.Control
                            name="distance_between_plants"
                            type="number"
                            value={budgetData.distance_between_plants}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>

              {/* Sistema de riego y agua */}
              <section className="mb-4 text-start">
                <div className="card" style={{ border: "#004077" }}>
                  <div
                    className="card-header text-white"
                    style={{ backgroundColor: "#004077" }}
                  >
                    <h6 className="mb-0">Sistema de riego y agua</h6>
                  </div>
                  <div className="card-body bg-black text-white">
                    <Row>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Sistema de riego</Form.Label>
                          <Form.Control
                            name="irrigation_system_type"
                            type="text"
                            value={budgetData.irrigation_system_type}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Fuente de agua</Form.Label>
                          <Form.Control
                            name="water_source"
                            type="text"
                            placeholder="Ejemplo: pozo, tanque"
                            value={budgetData.water_source}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Área total (m²)</Form.Label>
                          <Form.Control
                            name="total_area"
                            type="number"
                            value={budgetData.total_area}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>

              {/* Operaciones */}
              <section className="mb-4 text-start">
                <div className="card" style={{ border: "#004077" }}>
                  <div
                    className="card-header text-white"
                    style={{ backgroundColor: "#004077" }}
                  >
                    <h6 className="mb-0">Operaciones</h6>
                  </div>
                  <div className="card-body bg-black text-white">
                    <Row>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Número de operaciones</Form.Label>
                          <Form.Control
                            name="operations_number"
                            type="number"
                            value={budgetData.operations_number}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Tiempo por operación (min)</Form.Label>
                          <Form.Control
                            name="time_per_operation"
                            type="number"
                            step="0.01"
                            value={budgetData.time_per_operation}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>
                            Tiempo total de riego por día (min)
                          </Form.Label>
                          <Form.Control
                            name="total_irrigation_time_per_day"
                            type="number"
                            value={budgetData.total_irrigation_time_per_day}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>

              {/* Datos técnicos */}
              <section className="mb-4 text-start">
                <div className="card" style={{ border: "#004077" }}>
                  <div
                    className="card-header text-white"
                    style={{ backgroundColor: "#004077" }}
                  >
                    <h6 className="mb-0">Datos técnicos</h6>
                  </div>
                  <div className="card-body bg-black text-white">
                    <Row>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Caudal requerido (l/h)</Form.Label>
                          <Form.Control
                            name="required_flow_rate"
                            type="number"
                            value={budgetData.required_flow_rate}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Presión requerida (bar)</Form.Label>
                          <Form.Control
                            name="required_pressure"
                            type="number"
                            value={budgetData.required_pressure}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>
                            Profundidad instantánea de agua (cm)
                          </Form.Label>
                          <Form.Control
                            name="instantaneous_water_depth"
                            type="number"
                            step="0.01"
                            value={budgetData.instantaneous_water_depth}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>

              {/* Totales */}
              <section className="mb-4 text-start">
                <div className="card" style={{ border: "#004077" }}>
                  <div
                    className="card-header text-white"
                    style={{ backgroundColor: "#004077" }}
                  >
                    <h6 className="mb-0">Totales</h6>
                  </div>
                  <div className="card-body bg-black text-white">
                    <Row>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>
                            Profundidad total de agua (cm)
                          </Form.Label>
                          <Form.Control
                            name="total_water_depth"
                            type="number"
                            step="0.01"
                            value={budgetData.total_water_depth}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Cantidad total (USD)</Form.Label>
                          <Form.Control
                            name="total_amount"
                            type="number"
                            step="0.01"
                            value={budgetData.total_amount}
                            onChange={handleBudgetChange}
                            disabled={true}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Área de la primera etapa (m²)</Form.Label>
                          <Form.Control
                            name="first_stage_area"
                            type="number"
                            value={budgetData.first_stage_area}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>

              {/* Laterales */}
              <section className="mb-4 text-start">
                <div className="card" style={{ border: "#004077" }}>
                  <div
                    className="card-header text-white"
                    style={{ backgroundColor: "#004077" }}
                  >
                    <h6 className="mb-0">Laterales</h6>
                  </div>
                  <div className="card-body bg-black text-white">
                    <Row>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Laterales por línea</Form.Label>
                          <Form.Control
                            name="laterals_per_line"
                            type="number"
                            value={budgetData.laterals_per_line}
                            onChange={handleBudgetChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>
            </div>
          )}
          {currentStep === 2 && (
            <div className="w-100">
              <h5 className="mb-4 text-start d-flex align-items-center">
                Seleccionar productos para las etapas
                {/* <Button
                  variant="primary"
                  className="d-flex align-items-center gap-2 ms-auto"
                  style={{ width: "auto" }}
                  onClick={() => setShowCSVModal(true)}
                >
                  <AiOutlineFileExcel />
                  Importar desde CSV
                </Button> */}
              </h5>

              <CSVUploadModal
                show={showCSVModal}
                onHide={() => setShowCSVModal(false)}
                onFileUpload={handleFileUpload}
              />

              {selectedStages.map((stageUuid) => {
                const stage = stageTypes.find((s) => s.uuid === stageUuid);
                return (
                  <div key={stageUuid} className="mb-4">
                    <h6 className="text-start">
                      {stage?.name || "Nombre no disponible"}
                    </h6>

                    {/* Barra de búsqueda con botón */}
                    <div className="d-flex align-items-center mb-4">
                      <div className="flex-grow-1">
                        <Form.Control
                          type="text"
                          placeholder="Buscar producto por código"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearch(e.target.value, stageUuid);
                            }
                          }}
                        />
                      </div>
                      <button
                        className="btn btn-primary btn-sm ms-2 mt-0"
                        style={{
                          minWidth: "120px",
                          maxWidth: "120px",
                          height: "50px",
                        }}
                        onClick={(e) => {
                          const input =
                            e.target.previousElementSibling.querySelector(
                              "input"
                            );
                          handleSearch(input.value, stageUuid);
                        }}
                      >
                        Buscar
                      </button>
                    </div>

                    {/* Tabla de productos seleccionados */}
                    {productSelections.filter(
                      (product) => product.stageUuid === stageUuid
                    ).length > 0 && (
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-hover table-dark custom-table">
                          <thead className="custom-table-header-mini">
                            <tr>
                              <th>Producto</th>
                              <th>Proveedor</th>
                              <th>Precio Unit.</th>
                              <th>Cantidad</th>
                              <th>Cant. con error</th>
                              <th>Cant. con error redondeada</th>
                              <th>Ganancia (%)</th>
                              <th>Total</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody className="custom-table-body-mini">
                            {productSelections
                              .filter(
                                (product) => product.stageUuid === stageUuid
                              )
                              .map((product, index) => (
                                <tr key={`${stageUuid}-product-${index}`}>
                                  <td className="align-middle">
                                    {product.productDescription}
                                  </td>
                                  <td className="align-middle">
                                    {product.providerName}
                                  </td>
                                  <td className="align-middle text-end">
                                    ${Number(product.price).toFixed(2)}
                                  </td>
                                  <td style={{ width: "110px" }}>
                                    <Form.Control
                                      type="number"
                                      min="0"
                                      placeholder="Cantidad"
                                      value={product.quantity || ""}
                                      onChange={(event) => {
                                        const updatedSelections = [
                                          ...productSelections,
                                        ];
                                        const idx = productSelections.findIndex(
                                          (p) =>
                                            p.stageUuid === stageUuid &&
                                            p.productCode ===
                                              product.productCode
                                        );
                                        updatedSelections[idx].quantity =
                                          event.target.value;
                                        setProductSelections(updatedSelections);
                                      }}
                                      style={{
                                        height: "30px",
                                        fontSize: "14px",
                                      }}
                                    />
                                  </td>
                                  <td
                                    style={{ width: "90px" }}
                                    className="align-middle text-center"
                                  >
                                    {product.quantity
                                      ? Math.ceil(
                                          Number(product.quantity) +
                                            (Number(product.quantity) *
                                              Number(
                                                product.productError || 0
                                              )) /
                                              100
                                        )
                                      : "-"}
                                  </td>
                                  <td
                                    style={{ width: "90px" }}
                                    className="align-middle text-center"
                                  >
                                    {product.quantity
                                      ? (() => {
                                          // Calcular cantidad con error
                                          const quantityWithError =
                                            Number(product.quantity) +
                                            (Number(product.quantity) *
                                              Number(
                                                product.productError || 0
                                              )) /
                                              100;

                                          // Verificar si el producto tiene un mínimo por unidad
                                          if (
                                            product.productMinimumUnit === 1
                                          ) {
                                            return parseInt(quantityWithError); // Sin cambios si el mínimo es 1
                                          }

                                          // Calcular el menor múltiplo de productMinimumUnit mayor o igual a quantityWithError
                                          return (
                                            Math.ceil(
                                              quantityWithError /
                                                product.productMinimumUnit
                                            ) * product.productMinimumUnit
                                          );
                                        })()
                                      : "-"}
                                  </td>
                                  <td style={{ width: "110px" }}>
                                    <Form.Control
                                      type="number"
                                      step="0.01"
                                      min="0"
                                      placeholder="Ganancia"
                                      value={product.profitPercentage || ""}
                                      onChange={(event) => {
                                        const updatedSelections = [
                                          ...productSelections,
                                        ];
                                        const idx = productSelections.findIndex(
                                          (p) =>
                                            p.stageUuid === stageUuid &&
                                            p.productCode ===
                                              product.productCode
                                        );
                                        updatedSelections[
                                          idx
                                        ].profitPercentage = event.target.value;
                                        setProductSelections(updatedSelections);
                                      }}
                                      style={{
                                        height: "30px",
                                        fontSize: "14px",
                                      }}
                                    />
                                  </td>
                                  <td className="align-middle text-end">
                                    ${calculateTotal(product)}
                                  </td>
                                  <td className="align-middle text-center">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => {
                                        setProductSelections(
                                          productSelections.filter(
                                            (p) =>
                                              !(
                                                p.stageUuid === stageUuid &&
                                                p.productCode ===
                                                  product.productCode
                                              )
                                          )
                                        );
                                      }}
                                    >
                                      Eliminar
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <ProductSearchModal
                      show={showSearchModal}
                      onHide={() => setShowSearchModal(false)}
                      searchResults={searchResults}
                      onProductSelect={handleProductSelect}
                      currentStageUuid={selectedStageUuid}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {currentStep === 3 && (
            <BudgetPreview
              budgetData={budgetData}
              selectedStages={selectedStages}
              productSelections={productSelections}
              stageTypes={stageTypes}
              client={clients.find((c) => c.uuid === budgetData.uuid_client)}
              cropType={cropTypes.find(
                (c) => c.uuid === budgetData.uuid_crop_type
              )}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex justify-content-between">
          {currentStep > 0 && (
            <Button
              variant="primary"
              className="w-25"
              onClick={() => setCurrentStep((prev) => prev - 1)}
            >
              Anterior
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button
              variant="primary"
              className={currentStep > 0 ? "w-25" : "w-100"}
              onClick={() => setCurrentStep((prev) => prev + 1)}
            >
              Siguiente
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button variant="primary" className="w-25" onClick={handleSubmit}>
              Finalizar
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NewBudgetModal;
