import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewStageType,
  editCloseMessage,
  selectStageTypesErrorMessage,
  selectStageTypesSuccessMessage,
} from "../../redux/reducers/stageTypesReducer";

const NewStageTypeModal = ({
  showNewEntityModal,
  handleClose,
  handleSearch,
}) => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();

  // Mensaje de éxito o error
  const successMessage = useSelector(selectStageTypesSuccessMessage);
  const errorMessage = useSelector(selectStageTypesErrorMessage);

  const handleCloseMessage = () => {
    handleClose();
    setFormData({
      name: "",
      code: "",
      order: "",
    });
    dispatch(editCloseMessage());
    handleSearch();
  };

  // FormData
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    order: "",
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createNewStageType(formData));
  };

  return (
    <div>
      <Modal show={showNewEntityModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Nueva etapa</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: "8px" }}>
          <form style={{ display: "contents" }} onSubmit={handleSubmit}>
            <div>
              <Row className="pb-2">
                {/* Input para Nombre */}
                <Col md={6}>
                  <InputGroup>
                    <InputGroup.Text>Nombre:</InputGroup.Text>
                    <Form.Control
                      name="name"
                      type="text"
                      placeholder="Nombre..."
                      value={formData.name}
                      onChange={handleFormChange}
                      style={{ marginTop: 0, height: "100%" }}
                      required
                    />
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <InputGroup>
                    <InputGroup.Text>Código:</InputGroup.Text>
                    <Form.Control
                      name="code"
                      type="text"
                      placeholder="Código..."
                      value={formData.code}
                      onChange={handleFormChange}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^a-zA-Z]/g, "")
                          .slice(0, 1);
                      }}
                      style={{ marginTop: 0, height: "100%" }}
                      required
                    />
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <InputGroup>
                    <InputGroup.Text>Órden:</InputGroup.Text>
                    <Form.Control
                      name="order"
                      type="number"
                      placeholder="Órden..."
                      value={formData.order}
                      onChange={handleFormChange}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9]/g, "")
                          .slice(0, 2);
                      }}
                      style={{ marginTop: 0, height: "100%" }}
                      required
                    />
                  </InputGroup>
                </Col>
              </Row>
            </div>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Crear tipo de cultivo
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
        <Modal
          show={!!successMessage || !!errorMessage}
          onHide={handleCloseMessage}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {errorMessage ? "Error en la creación" : "Creación exitosa"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{successMessage || errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMessage}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal>
    </div>
  );
};

export default NewStageTypeModal;
