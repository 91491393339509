import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProviders,
  selectProviders,
  selectProvidersError,
  selectProvidersLoading,
  selectProviderById,
  clearProvider,
  fetchProviderById,
} from "../../redux/reducers/providersReducer";
import { selectLoggedUserProfile } from "../../redux/reducers/authReducer";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import SearchBar from "../../components/Searchbar";
import "../styles/Providers.css";
import NewProviderModal from "./NewProviderModal";
import EditProviderModal from "./EditProviderModal";
import DeleteProviderModal from "./DeleteProviderModal";
import { FaPlus, FaTruck } from "react-icons/fa";

const Providers = () => {
  // Llamadas a API y Redux
  const dispatch = useDispatch();
  const loading = useSelector(selectProvidersLoading);
  const providers = useSelector(selectProviders);
  const error = useSelector(selectProvidersError);
  const provider = useSelector(selectProviderById);
  const profilesAuth = useSelector(selectLoggedUserProfile);

  useEffect(() => {
    dispatch(fetchProviders(null));
  }, [dispatch]);

  const hasManageProvidersAccess = profilesAuth?.some((profile) =>
    profile.accesses?.some((access) => access.name === "manage_providers")
  );

  //Popups
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewEntityModal, setShowNewEntityModal] = useState(false);

  const handleEditOpen = (provider_uuid) => {
    dispatch(fetchProviderById(provider_uuid));
    setShowEditModal(true);
  };

  const handleEditClose = () => {
    dispatch(clearProvider());
    setShowEditModal(false);
  };

  const handleDeleteOpen = (provider_uuid) => {
    dispatch(fetchProviderById(provider_uuid));
    setShowDeleteModal(true);
  };

  const handleDeleteClose = (provider_uuid) => {
    dispatch(clearProvider());
    setShowDeleteModal(false);
  };

  const handleNewEntityOpen = () => {
    setShowNewEntityModal(true);
  };

  const handleNewEntityClose = () => {
    setShowNewEntityModal(false);
  };

  //Searchbar
  const handleSearch = (text) => {
    dispatch(fetchProviders(text));
  };

  return (
    <Container className="pt-4 pb-4">
      <Row>
        <Col>
          <Container className="pb-4 align-items-center">
            <Row className="align-items-center">
              <Col sm="3" md="3" lg="3">
                <h1 style={{ marginBottom: 0 }}>Proveedores</h1>
              </Col>
              <Col sm="7" md="7" lg="7">
                <SearchBar onSearch={handleSearch} parameterName={"nombre"} />
              </Col>
              {hasManageProvidersAccess && (
                <Col sm="2" md="2" lg="2">
                  <Button
                    variant="primary"
                    className="custom-button d-flex align-items-center justify-content-center"
                    onClick={handleNewEntityOpen}
                  >
                    <FaPlus />
                    <FaTruck size={30} style={{ marginLeft: "10px" }} />
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : providers.length === 0 ? (
            <p>No se encontraron proveedores.</p>
          ) : (
            <Table
              striped
              bordered
              hover
              variant="dark"
              className="custom-table"
            >
              <thead>
                <tr>
                  <th className="custom-table-header">Nombre</th>
                  <th className="custom-table-header">Teléfono</th>
                  <th
                    style={{ textAlign: "center" }}
                    className="custom-table-header"
                  >
                    Tipo
                  </th>
                  {hasManageProvidersAccess && (
                    <th
                      style={{ textAlign: "center", width: "120px" }}
                      className="custom-table-header"
                    >
                      Acciones
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {providers.map((provider) => (
                  <tr key={provider.uuid}>
                    <td>{provider.name}</td>
                    <td>{provider.phone}</td>
                    <td style={{ textAlign: "center" }}>
                      {provider.is_national ? "Nacional" : "Internacional"}
                    </td>
                    {hasManageProvidersAccess && (
                      <td style={{ textAlign: "center" }}>
                        <Button
                          variant="warning"
                          size="sm"
                          className="me-2"
                          onClick={() => handleEditOpen(provider.uuid)}
                        >
                          <PencilSquare />
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteOpen(provider.uuid)}
                        >
                          <Trash />
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {/*POPUPS*/}
          <NewProviderModal
            showNewEntityModal={showNewEntityModal}
            handleClose={handleNewEntityClose}
            handleSearch={handleSearch}
          />
          <EditProviderModal
            showEditModal={showEditModal}
            handleClose={handleEditClose}
            provider={provider}
            handleSearch={handleSearch}
          />
          <DeleteProviderModal
            showDeleteModal={showDeleteModal}
            handleClose={handleDeleteClose}
            provider={provider}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Providers;
