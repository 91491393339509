import React from "react";
import { Table } from "react-bootstrap";

const ProductSearchModal = ({
  show,
  onHide,
  searchResults,
  onProductSelect,
  currentStageUuid,
}) => {
  if (!show) return null;

  return (
    <>
      <div className="modal fade show d-block" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Resultados de búsqueda de productos
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={onHide}
                aria-label="Close"
              />
            </div>

            <div className="modal-body">
              <div className="table-responsive">
                <Table
                  striped
                  bordered
                  hover
                  variant="dark"
                  className="custom-table"
                >
                  <thead className="custom-table-header">
                    <tr>
                      <th>Código</th>
                      <th>Descripción</th>
                      <th>Unidad</th>
                      <th>% Error</th>
                      <th>Proveedor</th>
                      <th className="text-end">Precio</th>
                      <th className="text-center">Última actualización</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.length === 0 ? (
                      <tr>
                        <td colSpan={7} className="text-center text-muted">
                          No se encontraron productos
                        </td>
                      </tr>
                    ) : (
                      searchResults.map((product, index) => (
                        <tr key={index}>
                          <td className="font-monospace">
                            {product.product_code}
                          </td>
                          <td>{product.product_description}</td>
                          <td className="text-center">
                            {product.product_unit}
                          </td>
                          <td className="text-center">
                            {parseInt(product.product_error)}
                          </td>
                          <td>{product.provider_name}</td>
                          {console.log(product)}
                          <td className="text-end">
                            ${Number(product.price).toFixed(2)}
                          </td>
                          <td className="text-center">
                            {new Date(product.updated_at).toLocaleDateString()}
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                const newProduct = {
                                  uuidProductProvider:
                                    product.uuid_product_provider,
                                  stageUuid: currentStageUuid,
                                  productCode: product.product_code,
                                  productDescription:
                                    product.product_description,
                                  providerName: product.provider_name,
                                  price: product.price,
                                  unit: product.product_unit,
                                  quantity: 0,
                                  profitPercentage: 0,
                                  productError: product.product_error,
                                  productMinimumUnit: product.product_minimum_unit,
                                };
                                onProductSelect(newProduct);
                              }}
                            >
                              Seleccionar
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export default ProductSearchModal;
